import { createInfiniteQuery } from '@tanstack/solid-query'
import { createFocusTrap } from 'focus-trap'
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  Match,
  on,
  onCleanup,
  onMount,
  Show,
  Suspense,
  Switch,
  type Component,
} from 'solid-js'
import { produce } from 'solid-js/store'
import { lock, unlock } from 'tua-body-scroll-lock'
import { useWpApi } from '../../api'
import { DevOnly } from '../../components/DevOnly'
import { Icon, type IconsChoices } from '../../components/Icons'
import { SafeHtml } from '../../components/SafeHtml'
import { HoverLineArtBlue_Clouds } from '../../components/svg/HoverArtSvg'
import type { SingleStaff } from '../../types/StaffTypes'
import { useStaffContext } from './StaffAppContext'
import { StaffSearch } from './StaffSearch'
type StaffGridProps = {
  // staff:StaffList
}

export const StaffGrid: Component<StaffGridProps> = () => {
  const { searchFormState, setSearchFormState } = useStaffContext()
  const queryMin3 = createMemo(() =>
    searchFormState.query.length >= 3 ? searchFormState.query : ''
  )

  const staffInfiniteQuery = createInfiniteQuery(() => ({
    queryKey: [
      'fetchStaff',
      queryMin3(),
      searchFormState.departments,
      searchFormState.order,
      searchFormState.orderby,
    ],

    queryFn: async ({ pageParam }) => {
      const staff = await useWpApi.fetchStaff({
        search: queryMin3(),
        department: searchFormState.departments.map((dept) => Number(dept.value)),
        order: searchFormState.order,
        orderby: searchFormState.orderby,
        page: pageParam,
      })

      return staff
    },

    // gcTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60, // 1 hour

    throwOnError: true, // Throw an error if the query fails
    initialPageParam: 1,
    getNextPageParam: (page) => {
      const nextPage = page.currentPage + 1
      if (nextPage > page.totalPages) return undefined
      return nextPage
    },
  }))

  return (
    <div class="pb-bodyGutterDesktop">
      <div>
        <StaffSearch placeholder="Search for names or keywords" searchButtonText="Search" />
      </div>
      <Switch>
        <Match when={staffInfiniteQuery.isPending}>
          <div class="py-20 text-center">
            <Icon icon={'loader'} class="mx-auto size-40 text-blue" />
          </div>
        </Match>
        <Match when={staffInfiniteQuery.isRefetching}>
          <p>Refetching...</p>
        </Match>
        <Match when={staffInfiniteQuery.isError}>
          <p>Error: {staffInfiniteQuery.error?.message}</p>
        </Match>
        <Match
          when={
            staffInfiniteQuery.data?.pages.length === 1 &&
            staffInfiniteQuery.data.pages[0].results.length === 0
          }
        >
          <div class="space-y-20 text-center">
            <h2 class="type-h5-bold">No results found. Try clearing all filters.</h2>
            <div>
              <button
                class="btn"
                onClick={() => {
                  setSearchFormState(
                    produce((state) => {
                      state.departments = []
                      state.query = ''
                      state.order = 'asc'
                      state.orderby = 'title'
                    })
                  )
                }}
              >
                Clear All Filters
              </button>
            </div>
          </div>
        </Match>
        <Match when={staffInfiniteQuery.isSuccess && staffInfiniteQuery.data} keyed>
          {(data) => (
            <Suspense>
              <div class="grid grid-cols-2 items-start gap-20 md:grid-cols-4 md:gap-40">
                <For each={data.pages}>
                  {(page) => (
                    <For each={page.results}>
                      {(staff, index) => {
                        return <StaffTile person={staff} index={index()} />
                      }}
                    </For>
                  )}
                </For>
              </div>

              <StaffModal />
            </Suspense>
          )}
        </Match>
      </Switch>
      <Show when={staffInfiniteQuery.hasNextPage}>
        <Show when={staffInfiniteQuery.isFetchingNextPage}>
          <div class="py-20 text-center">
            <Icon icon={'loader'} class="mx-auto size-40 text-blue" />
          </div>
        </Show>
        <div class="mt-40 text-center md:mt-80">
          <button
            onClick={() => staffInfiniteQuery.fetchNextPage()}
            class="!type-p2-semibold btn-outline-blue !leading-none"
          >
            Load More
          </button>
        </div>
      </Show>
      <DevOnly class="fixed bottom-0 left-0">
        <pre>{JSON.stringify(searchFormState, null, 2)}</pre>
      </DevOnly>
    </div>
  )
}

const StaffTile: Component<{
  person: SingleStaff
  index: number
}> = (props) => {
  const { setOpenStaffModal } = useStaffContext()

  return (
    <button
      class="group text-left"
      onClick={(e) => {
        e.preventDefault()

        setOpenStaffModal(props.person)
      }}
    >
      <div class="relative mb-10">
        {/* default image */}
        <img
          src={props.person.acf.staff.headshot.sizes.medium_large}
          alt={props.person.acf.staff.headshot.alt}
          class="aspect-square w-full rounded-10px object-cover group-hover:opacity-0"
          loading={'lazy'}
        />

        {/* hover image */}

        <Show
          when={props.person.acf.staff.hover_art}
          keyed
          fallback={
            <HoverLineArtBlue_Clouds class="absolute inset-0 h-full w-full opacity-0 group-hover:opacity-100" />
          }
        >
          {(bg) => (
            <div>
              <img
                src={bg}
                alt={'hover art'}
                class="absolute inset-0 h-full w-full object-cover opacity-0 group-hover:opacity-100"
              />
            </div>
          )}
        </Show>

        <img
          src={props.person.acf.staff.headshot_hover.sizes.medium_large}
          alt={props.person.acf.staff.headshot_hover.alt}
          class="absolute inset-0 aspect-square w-full rounded-10px object-cover opacity-0 group-hover:opacity-100"
          loading={'lazy'}
        />
      </div>
      <div class="space-y-6">
        <For each={props.person.acf.staff.department}>
          {(dept) => (
            <p class="type-mobile-h7-semibold-all-caps text-adaAltBlue md:type-h7-semibold-all-caps">
              {dept.name}
            </p>
          )}
        </For>
        <h2 class="type-mobile-h4-semibold text-blue md:type-h4-semibold max-md:!text-20">
          {props.person.title.rendered}
        </h2>
        <p class="type-mobile-p3-medium text-black md:type-p3-medium">
          {props.person.acf.staff.position}
        </p>
      </div>
    </button>
  )
}

const StaffModal: Component = () => {
  const { openStaffModal, setOpenStaffModal } = useStaffContext()

  const [dialogRef, setDialogRef] = createSignal<HTMLDialogElement>()

  const [enableFocusTrap, setEnableFocusTrap] = createSignal(false)

  const handleCloseDialog = () => {
    dialogRef()?.close()
    setOpenStaffModal(undefined)
  }

  createEffect(
    on(
      openStaffModal,
      (openStaffModal) => {
        if (openStaffModal) {
          dialogRef()?.showModal()
          setEnableFocusTrap(true)
        } else {
          setEnableFocusTrap(false)
        }
      },
      { defer: false }
    )
  )

  const focusTrap = createMemo(() =>
    createFocusTrap(dialogRef() as HTMLElement, {
      clickOutsideDeactivates: true,
      escapeDeactivates: false,
      onActivate() {
        // console.log('Focus trap activated')
        lock(dialogRef() as HTMLElement)
        // scroll to top of modal
        dialogRef()?.scrollTo(0, 0)
        document.documentElement.style.setProperty('overflow', 'hidden', 'important')
      },
      onDeactivate() {
        // console.log('Focus trap deactivated')
        unlock(dialogRef() as HTMLElement)
        document.documentElement.style.removeProperty('overflow')
      },
    })
  )

  onMount(() => {
    const dialogElement = dialogRef()

    dialogElement?.addEventListener('close', handleCloseDialog)

    const backdropClickHandler = (event: MouseEvent) => {
      if (event.target === dialogElement) {
        handleCloseDialog()
      }
    }

    dialogElement?.addEventListener('click', backdropClickHandler)

    onCleanup(() => {
      dialogElement?.removeEventListener('close', handleCloseDialog)
      dialogElement?.removeEventListener('click', backdropClickHandler)
    })
  })

  createEffect(() => {
    if (enableFocusTrap()) {
      focusTrap().activate()
    } else {
      focusTrap().deactivate()
    }
  })

  const ContactLink: Component<{
    icon: IconsChoices
    type: 'tel:' | 'mailto:'
    url: string
  }> = (props) => {
    return (
      <a
        href={props.type + props.url}
        class="type-p3-semibold flex items-center gap-10 text-blue link-hover"
      >
        <span>
          <Icon icon={props.icon} class="size-16" />
        </span>
        <span>{props.url}</span>
      </a>
    )
  }

  const ContactInfo: Component<SingleStaff & { class?: string }> = (props) => {
    return (
      <div class={props.class}>
        <Show when={props.acf.staff.phone_number}>
          <ContactLink icon={'phone'} type={'tel:'} url={props.acf.staff.phone_number!} />
        </Show>
        <Show when={props.acf.staff.email}>
          <ContactLink icon={'email'} type={'mailto:'} url={props.acf.staff.email!} />
        </Show>
        <div class="flex gap-20 text-blue">
          <For each={props.acf.staff.socials.array}>
            {(item) => (
              <Show when={item.url}>
                <a href={item.url!} class="link-hover" target="_blank">
                  <Icon icon={item.name} class="size-24" />
                </a>
              </Show>
            )}
          </For>
        </div>
      </div>
    )
  }
  return (
    <dialog
      ref={setDialogRef}
      class="fixed inset-0 z-50 h-auto rounded-10px bg-transparant backdrop:block backdrop:bg-blue backdrop:bg-opacity-50 md:h-fit"
    >
      <div class="relative w-full max-w-920 rounded-10px bg-white">
        <div class="sticky right-0 top-0 z-50">
          <button
            class="absolute right-0 top-0 flex size-40 items-center justify-center rounded-10px bg-white text-blue link-hover md:size-60"
            onClick={handleCloseDialog}
            autofocus={true}
          >
            <span class="sr-only">close modal</span>
            <Icon icon={'close'} class="size-14" />
          </button>
        </div>

        <div class="max-h-full overflow-auto px-20 py-40 md:p-40">
          <Show when={openStaffModal()} keyed>
            {(person) => (
              <div class="flex flex-col items-center gap-20 md:flex-row md:gap-40">
                <div class="space-y-20 md:max-w-270">
                  <div class="relative space-y-20">
                    <div class="space-y-10">
                      {/* default image */}
                      <img
                        src={person.acf.staff.headshot.sizes.medium_large}
                        alt={person.acf.staff.headshot.alt}
                        class="aspect-square w-full rounded-10px object-cover"
                        loading={'lazy'}
                      />
                      <a
                        href={person.acf.staff.headshot.url}
                        download
                        class="btn-outline-blue-small w-full gap-x-10 max-md:py-5 max-md:text-14"
                      >
                        <span>
                          <Icon icon={'download'} class="size-16" />
                        </span>
                        <span>DOWNLOAD HEADSHOT</span>
                      </a>
                    </div>
                    <ContactInfo {...person} class="hidden space-y-14 md:block" />
                  </div>
                </div>
                <div class="w-full space-y-10">
                  <div>
                    <For each={person.acf.staff.department}>
                      {(dept) => (
                        <p class="type-mobile-h7-semibold-all-caps text-adaAltBlue">{dept.name}</p>
                      )}
                    </For>
                  </div>
                  <h2 class="type-mobile-h4-semibold text-blue md:type-h4-semibold">
                    {person.title.rendered}
                  </h2>
                  <p class="type-p4-medium mb-20 text-black max-md:text-14">
                    {person.acf.staff.position}
                  </p>
                  <SafeHtml
                    input={person.acf.staff.bio}
                    tag={'div'}
                    class="type-p4-medium md:type-p3-medium"
                  />
                  <ContactInfo {...person} class="space-y-14 pt-30 md:hidden" />
                </div>
              </div>
            )}
          </Show>
        </div>
      </div>
    </dialog>
  )
}

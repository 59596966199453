import { createContextProvider } from '@solid-primitives/context'
import { makePersisted } from '@solid-primitives/storage'

import { createQuery } from '@tanstack/solid-query'
import queryString from 'query-string'
import { createEffect, createMemo, on } from 'solid-js'
import { createStore, produce } from 'solid-js/store'
import { useWpApi } from '../api'
import { qs } from '../helpers'
import type { AppSettings } from '../types'

export type ActiveFiltersType = {
  'league-program-area': string[]
  'focus-area': string[]
  region: string[]
  location_population: string[]
}

export type FilterKeys = keyof ActiveFiltersType

const [AppContextProvider, useApp] = createContextProvider(() => {
  // const [searchParams, setSearchParams] = useSearchParams()

  const [activeFilters, setActiveFilters] = createStore<
    {
      label: string
      key: FilterKeys
      value: string | number
    }[]
  >([])

  const [mmlLocalStorage, setMmlLocalStorage] = makePersisted(
    // eslint-disable-next-line solid/reactivity
    createStore<{
      favorites: {
        id: number
      }[]

      savedSearches: {
        query: string
      }[]
    }>({
      favorites: [],
      savedSearches: [],
    }),
    {
      name: 'mml-idea-bank-local-storage',
      storage: localStorage,
    }
  )

  const addSavedSearch = (query: string) => {
    setMmlLocalStorage(
      'savedSearches',
      produce((savedSearches) => {
        const searchAlreadyExists = savedSearches.some((savedSearch) => savedSearch.query === query)
        if (!searchAlreadyExists) {
          savedSearches.push({ query: query })
        }
      })
    )
  }

  const removeSavedSearch = (query: string) => {
    setMmlLocalStorage(
      'savedSearches',
      produce((savedSearches) => {
        const index = savedSearches.findIndex((savedSearch) => savedSearch.query === query)
        if (index !== -1) {
          savedSearches.splice(index, 1)
        }
      })
    )
  }

  const addFavorite = (id: number) => {
    setMmlLocalStorage(
      'favorites',
      produce((favorites) => {
        if (!favorites.find((favorite) => favorite.id === id)) {
          favorites.push({ id: id })
        }
      })
    )
  }

  const removeFavorite = (id: number) => {
    setMmlLocalStorage(
      'favorites',
      produce((favorites) => {
        const index = favorites.findIndex((favorite) => favorite.id === id)
        if (index !== -1) {
          favorites.splice(index, 1)
        }
      })
    )
  }

  const [activeQuery, setActiveQuery] = createStore<{
    currentPage: number
    activeFilters: ActiveFiltersType
    searchQuery: string
    settings: {
      filters: {
        key: FilterKeys
        label: string
      }[]
    }
    appSettings?: AppSettings
  }>({
    currentPage: 1,
    searchQuery: '',
    activeFilters: {
      'league-program-area': [],
      'focus-area': [],
      location_population: [],
      region: [],
    },
    appSettings: undefined,
    settings: {
      filters: [
        {
          key: 'league-program-area',
          label: 'League Program Areas',
        },
        {
          key: 'focus-area',
          label: 'Focus Areas',
        },
        {
          key: 'location_population',
          label: 'Location Populations',
        },
        {
          key: 'region',
          label: 'Regions',
        },
      ],
    },
  })
  const urlState = createMemo(
    () =>
      `?${qs.stringify(
        {
          ...activeQuery.activeFilters,
          searchQuery: activeQuery.searchQuery,
          // currentPage: activeQuery.currentPage,
        },
        {
          skipEmptyString: true,
        }
      )}`
  )

  const filterQueryString = createMemo(() =>
    queryString.stringify(
      {
        ...activeQuery.activeFilters,
      },
      {
        skipEmptyString: true,
      }
    )
  )
  const searchQueryString = createMemo(() =>
    queryString.stringify(
      {
        searchQuery: activeQuery.searchQuery,
      },
      {
        skipEmptyString: true,
      }
    )
  )

  createEffect(
    on(
      () => activeQuery.currentPage,
      (currentPage) => {
        console.log('page change')
        // scroll to top of page
        window.scrollTo(0, 0)
      },
      { defer: true }
    )
  )
  const caseStudiesAppNavPathQuery = createQuery(() => ({
    queryKey: ['case-studies-app-nav-path'],
    queryFn: async () => {
      const settings = await useWpApi.getAppSettings()

      return settings
    },
    // gcTime: 1000 * 60 * 60, // 1 hour

    throwOnError: true, // Throw an error if the query fails
  }))

  return {
    activeQuery,
    caseStudiesAppNavPathQuery,
    setActiveQuery,
    mmlLocalStorage,
    setMmlLocalStorage,
    urlState,
    addFavorite,
    removeFavorite,
    addSavedSearch,
    removeSavedSearch,
    filterQueryString,
    searchQueryString,
    activeFilters,
    setActiveFilters,
  }
})

const useAppContext = () => useApp()!

export { AppContextProvider, useAppContext }

import { createBreakpoints } from '@solid-primitives/media'
import { clsx, type ClassValue } from 'clsx'
import DOMPurify from 'dompurify'
import { tailwindTheme } from './tailwind/tailwind-theme'

import usePopper from 'solid-popper'

export const toggleNumberArrayItem = (arr: number[], item: number) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]

export const toggleStringArrayItem = (arr: string[], item: string) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]

export function cx(...args: ClassValue[]) {
  return clsx(args)
}

const screens = {
  ...tailwindTheme.screens,
} as const

export const breakpoints = createBreakpoints(screens)

export const stripHtml = (content: string) => {
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [],
  })
}

export const convertStringArrayToNumbers = (array: string[]) => {
  return array.map((item) => parseInt(item))
}

export const convertStringToNumbersArray = (string?: string) => {
  if (!string) return []
  const array = string.split(',')
  return array.map((item) => parseInt(item))
}

export const convertStringToStringArray = (string?: string) => {
  if (!string) return []
  return string.split(',')
}

export const logger = (value: unknown, type?: 'log' | 'error') => {
  if (type === 'error') console.error(value)
  else console.log(value)
}

export const stripHtmlAndAddLineBreaks = (content: string) => {
  // Replace <p> tags with their content followed by two line breaks
  let result = content.replace(/<p\b[^>]*>(.*?)<\/p>/gi, '$1\n\n')

  // Replace other block-level elements with a single line break
  result = result.replace(/<\/(div|h[1-6]|table|ul|ol|blockquote)>/gi, '$&\n')

  // Remove all remaining HTML tags
  result = result.replace(/<[^>]+>/g, '')

  // Decode HTML entities
  result = result
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")

  // Preserve multiple consecutive line breaks (up to 2)
  result = result.replace(/\n{3,}/g, '\n\n')

  // Remove extra whitespace within lines, but preserve line breaks
  result = result
    .split('\n')
    .map((line) => line.trim())
    .join('\n')

  return result.trim()
}

export { usePopper }

export function getCurrentDate() {
  return new Intl.DateTimeFormat('en-CA', {
    // en-CA uses YYYY-MM-DD format
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date())
}

export function sanitizeFileName(input: string): string {
  // Replace spaces with underscores
  input = input.replace(/\s+/g, '_')

  // Remove all non-alphanumeric characters, dashes, and underscores
  return input.replace(/[^a-zA-Z0-9-_]/g, '')
}

export function decodeHtmlEntities(text: string): string {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text
  return textArea.value
}

export function generateMailtoShareUrl({
  to,
  subject,
  body,
}: {
  to?: string
  subject: string
  body: string
}): string {
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)
  return `mailto:${to ? to : ' '}?subject=${encodedSubject}&body=${encodedBody}`
}

import type { Component } from 'solid-js'
import { z } from 'zod'
import type { HtmlElementHelper } from '../helpers'

interface IconProps extends HtmlElementHelper {
  icon: keyof typeof iconSvgs
}

export const Icon: Component<IconProps> = (props) => {
  return <>{iconSvgs[props.icon]({ class: props.class })}</>
}

export const iconChoices = [
  'download',
  'search',
  'heart',
  'unheart',
  'share',
  'favorite',
  //   'hamburger',
  'sort',
  'chevronRight',
  'chevronLeft',
  'locationPin',
  'chevronDown',
  'close',
  'loader',
  //   'filter',
  'plus',
  'minus',
  'attachment',
  'video',
  //   'success',
  //   'remove',
  //   'zoom',
  'phone',
  'facebook',
  'twitter',
  'instagram',
  'linkedin',
  'email',
  'checkMark',
] as const

export const IconSchema = z.enum(iconChoices)

export type IconsChoices = z.infer<typeof IconSchema>

const iconSvgs: Record<IconsChoices, Component<HtmlElementHelper>> = {
  phone: (props) => (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M14.6667 10.9167C13.625 10.9167 12.625 10.75 11.6917 10.4417C11.4 10.35 11.075 10.4167 10.8417 10.6417L9.00833 12.475C6.65 11.275 4.71667 9.35 3.51667 6.98333L5.35 5.14167C5.58333 4.925 5.65 4.6 5.55833 4.30833C5.25 3.375 5.08333 2.375 5.08333 1.33333C5.08333 0.875 4.70833 0.5 4.25 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333C0.5 9.15833 6.84167 15.5 14.6667 15.5C15.125 15.5 15.5 15.125 15.5 14.6667V11.75C15.5 11.2917 15.125 10.9167 14.6667 10.9167ZM13.8333 8H15.5C15.5 3.85833 12.1417 0.5 8 0.5V2.16667C11.225 2.16667 13.8333 4.775 13.8333 8ZM10.5 8H12.1667C12.1667 5.7 10.3 3.83333 8 3.83333V5.5C9.38333 5.5 10.5 6.61667 10.5 8Z"
        fill="currentColor"
      />
    </svg>
  ),
  download: (props) => (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M8 12.5699L3 7.56989L4.4 6.11989L7 8.71988V0.569885H9V8.71988L11.6 6.11989L13 7.56989L8 12.5699ZM2 16.5699C1.45 16.5699 0.979333 16.3742 0.588 15.9829C0.196666 15.5916 0.000666667 15.1206 0 14.5699V11.5699H2V14.5699H14V11.5699H16V14.5699C16 15.1199 15.8043 15.5909 15.413 15.9829C15.0217 16.3749 14.5507 16.5706 14 16.5699H2Z"
        fill="currentColor"
      />
    </svg>
  ),
  sort: (props) => (
    <svg
      class={props.class}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
    </svg>
  ),
  checkMark: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      class={props.class}
    >
      <path
        fill="currentColor"
        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
      />
    </svg>
  ),
  unheart: (props) => (
    <svg
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M13.4961 9.06989H11.2461V7.56989H13.4961H14.9961H17.2461V9.06989H14.9961H13.4961ZM8.2461 14.3199L5.86484 12.1824C4.96484 11.3699 4.19309 10.6449 3.54959 10.0074C2.90559 9.36989 2.37434 8.76989 1.95584 8.20739C1.53684 7.64489 1.23059 7.10114 1.03709 6.57614C0.843094 6.05114 0.746094 5.50114 0.746094 4.92614C0.746094 3.75114 1.13984 2.77289 1.92734 1.99139C2.71484 1.21039 3.69609 0.819885 4.87109 0.819885C5.52109 0.819885 6.13985 0.954135 6.72735 1.22264C7.31485 1.49164 7.8211 1.87614 8.2461 2.37614C8.6711 1.87614 9.17735 1.49164 9.76485 1.22264C10.3523 0.954135 10.9711 0.819885 11.6211 0.819885C12.6836 0.819885 13.5741 1.14164 14.2926 1.78514C15.0116 2.42914 15.4586 3.18239 15.6336 4.04489C15.4086 3.95739 15.1836 3.89164 14.9586 3.84764C14.7336 3.80414 14.5148 3.78239 14.3023 3.78239C13.0398 3.78239 11.9648 4.22289 11.0773 5.10389C10.1898 5.98538 9.7461 7.05739 9.7461 8.31989C9.7461 8.96988 9.87735 9.58539 10.1398 10.1664C10.4023 10.7479 10.7711 11.2449 11.2461 11.6574C11.0086 11.8699 10.6991 12.1419 10.3176 12.4734C9.9366 12.8044 9.60859 13.0949 9.3336 13.3449L8.2461 14.3199Z"
        fill="currentColor"
      />
    </svg>
  ),
  email: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class={props.class}
    >
      <path
        fill="currentColor"
        d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
      />
    </svg>
  ),
  facebook: (props) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        fill="currentColor"
        d="M21.3333 0H2.66667C1.19333 0 0 1.19333 0 2.66667V21.3333C0 22.8067 1.19333 24 2.66667 24H12.828V14.7187H9.704V11.0853H12.828V8.412C12.828 5.31333 14.7227 3.624 17.488 3.624C18.42 3.62133 19.3507 3.66933 20.2773 3.764V7.004H18.3733C16.8667 7.004 16.5733 7.716 16.5733 8.76667V11.08H20.1733L19.7053 14.7133H16.552V24H21.3333C22.8067 24 24 22.8067 24 21.3333V2.66667C24 1.19333 22.8067 0 21.3333 0Z"
      />
    </svg>
  ),
  twitter: (props) => (
    <svg
      class={props.class}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.01078 0.748047L9.11957 12.7483L0.854004 22.748H3.97809L10.5168 14.8155L15.8773 22.748H22.854L14.3579 10.1558L22.1139 0.748047H19.0382L12.9676 8.09332L8.01288 0.748047H1.01078Z"
        fill="currentColor"
      />
    </svg>
  ),
  instagram: (props) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M12.0054 5.84643C8.59935 5.84643 5.85206 8.5936 5.85206 11.9994C5.85206 15.4053 8.59935 18.1525 12.0054 18.1525C15.4114 18.1525 18.1587 15.4053 18.1587 11.9994C18.1587 8.5936 15.4114 5.84643 12.0054 5.84643ZM12.0054 15.9997C9.80431 15.9997 8.00491 14.2058 8.00491 11.9994C8.00491 9.79315 9.79895 7.99919 12.0054 7.99919C14.2118 7.99919 16.0058 9.79315 16.0058 11.9994C16.0058 14.2058 14.2064 15.9997 12.0054 15.9997ZM19.8456 5.59475C19.8456 6.39265 19.2029 7.02991 18.4104 7.02991C17.6124 7.02991 16.9751 6.3873 16.9751 5.59475C16.9751 4.80219 17.6178 4.15958 18.4104 4.15958C19.2029 4.15958 19.8456 4.80219 19.8456 5.59475ZM23.921 7.05133C23.83 5.12885 23.3908 3.42593 21.9824 2.02289C20.5793 0.619853 18.8763 0.180735 16.9537 0.0843429C14.9722 -0.0281143 9.03314 -0.0281143 7.05166 0.0843429C5.13444 0.17538 3.43144 0.614498 2.02298 2.01753C0.614527 3.42057 0.180743 5.1235 0.0843468 7.04598C-0.0281156 9.02737 -0.0281156 14.9662 0.0843468 16.9476C0.175388 18.87 0.614526 20.573 2.02298 21.976C3.43144 23.379 5.12909 23.8182 7.05166 23.9146C9.03314 24.027 14.9722 24.027 16.9537 23.9146C18.8763 23.8235 20.5793 23.3844 21.9824 21.976C23.3855 20.573 23.8246 18.87 23.921 16.9476C24.0335 14.9662 24.0335 9.03272 23.921 7.05133ZM21.3612 19.0735C20.9434 20.1231 20.1348 20.9318 19.0798 21.3548C17.4999 21.9814 13.7512 21.8368 12.0054 21.8368C10.2595 21.8368 6.50541 21.976 4.93094 21.3548C3.88129 20.9371 3.07263 20.1285 2.64956 19.0735C2.02298 17.4938 2.16758 13.7452 2.16758 11.9994C2.16758 10.2537 2.02834 6.49976 2.64956 4.92536C3.06728 3.87576 3.87593 3.06714 4.93094 2.64408C6.51077 2.01754 10.2595 2.16212 12.0054 2.16212C13.7512 2.16212 17.5053 2.02289 19.0798 2.64408C20.1294 3.06178 20.9381 3.8704 21.3612 4.92536C21.9877 6.50511 21.8431 10.2537 21.8431 11.9994C21.8431 13.7452 21.9877 17.4991 21.3612 19.0735Z"
        fill="currentColor"
      />
    </svg>
  ),
  linkedin: (props) => (
    <svg
      class={props.class}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2857 0H1.70893C0.766071 0 0 0.77675 0 1.73028V22.2686C0 23.2221 0.766071 23.9989 1.70893 23.9989H22.2857C23.2286 23.9989 24 23.2221 24 22.2686V1.73028C24 0.77675 23.2286 0 22.2857 0ZM7.25357 20.5705H3.69643V9.11744H7.25893V20.5705H7.25357ZM5.475 7.55323C4.33393 7.55323 3.4125 6.62648 3.4125 5.49082C3.4125 4.35516 4.33393 3.42841 5.475 3.42841C6.61071 3.42841 7.5375 4.35516 7.5375 5.49082C7.5375 6.63184 6.61607 7.55323 5.475 7.55323ZM20.5875 20.5705H17.0304V14.9993C17.0304 13.6708 17.0036 11.962 15.1821 11.962C13.3286 11.962 13.0446 13.4083 13.0446 14.9029V20.5705H9.4875V9.11744H12.9V10.6817H12.9482C13.425 9.78169 14.5875 8.83352 16.3179 8.83352C19.9179 8.83352 20.5875 11.2066 20.5875 14.2922V20.5705Z"
        fill="currentColor"
      />
    </svg>
  ),
  video: (props) => (
    <svg
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M14 5.06982V1.56982C14 1.01982 13.55 0.569824 13 0.569824H1C0.45 0.569824 0 1.01982 0 1.56982V11.5698C0 12.1198 0.45 12.5698 1 12.5698H13C13.55 12.5698 14 12.1198 14 11.5698V8.06982L18 12.0698V1.06982L14 5.06982Z"
        fill="currentColor"
      />
    </svg>
  ),
  attachment: (props) => (
    <svg
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M20 5.56982C20 8.60982 17.54 11.0698 14.5 11.0698L4 11.0698C1.79 11.0698 -1.56487e-07 9.27983 -3.49691e-07 7.06983C-5.42895e-07 4.85983 1.79 3.06983 4 3.06983L12.5 3.06982C13.88 3.06982 15 4.18982 15 5.56982C15 6.94982 13.88 8.06982 12.5 8.06982L5 8.06983L5 6.06983L12.59 6.06982C13.14 6.06982 13.14 5.06982 12.59 5.06982L4 5.06983C2.9 5.06983 2 5.96983 2 7.06983C2 8.16983 2.9 9.06983 4 9.06983L14.5 9.06982C16.43 9.06982 18 7.49982 18 5.56982C18 3.63982 16.43 2.06982 14.5 2.06982L5 2.06983L5 0.0698255L14.5 0.0698247C17.54 0.0698244 20 2.52982 20 5.56982Z"
        fill="currentColor"
      />
    </svg>
  ),
  locationPin: (props) => (
    <svg
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M2 18.5698C1.45 18.5698 0.979167 18.374 0.5875 17.9823C0.195833 17.5907 0 17.1198 0 16.5698V2.56982C0 2.01982 0.195833 1.54899 0.5875 1.15732C0.979167 0.765658 1.45 0.569824 2 0.569824H16C16.55 0.569824 17.0208 0.765658 17.4125 1.15732C17.8042 1.54899 18 2.01982 18 2.56982V16.5698C18 17.1198 17.8042 17.5907 17.4125 17.9823C17.0208 18.374 16.55 18.5698 16 18.5698H2ZM9 3.56982C7.73333 3.56982 6.61667 4.01149 5.65 4.89482C4.68333 5.77816 4.2 6.97816 4.2 8.49482C4.2 9.71149 4.675 10.9115 5.625 12.0948C6.575 13.2782 7.475 14.2448 8.325 14.9948C8.425 15.0782 8.53333 15.1407 8.65 15.1823C8.76667 15.224 8.88333 15.2448 9 15.2448C9.11667 15.2448 9.23333 15.224 9.35 15.1823C9.46667 15.1407 9.575 15.0782 9.675 14.9948C10.525 14.2448 11.425 13.2782 12.375 12.0948C13.325 10.9115 13.8 9.71149 13.8 8.49482C13.8 6.97816 13.3167 5.77816 12.35 4.89482C11.3833 4.01149 10.2667 3.56982 9 3.56982ZM9 9.81982C8.65 9.81982 8.35417 9.69899 8.1125 9.45732C7.87083 9.21566 7.75 8.91982 7.75 8.56982C7.75 8.21982 7.87083 7.92399 8.1125 7.68232C8.35417 7.44066 8.65 7.31982 9 7.31982C9.35 7.31982 9.64583 7.44066 9.8875 7.68232C10.1292 7.92399 10.25 8.21982 10.25 8.56982C10.25 8.91982 10.1292 9.21566 9.8875 9.45732C9.64583 9.69899 9.35 9.81982 9 9.81982Z"
        fill="currentColor"
      />
    </svg>
  ),
  share: (props) => (
    <svg
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M11.5 10.6299C10.93 10.6299 10.42 10.8549 10.03 11.2074L4.6825 8.09495C4.72 7.92245 4.75 7.74995 4.75 7.56995C4.75 7.38995 4.72 7.21745 4.6825 7.04495L9.97 3.96245C10.375 4.33745 10.9075 4.56995 11.5 4.56995C12.745 4.56995 13.75 3.56495 13.75 2.31995C13.75 1.07495 12.745 0.0699463 11.5 0.0699463C10.255 0.0699463 9.25 1.07495 9.25 2.31995C9.25 2.49995 9.28 2.67245 9.3175 2.84495L4.03 5.92745C3.625 5.55245 3.0925 5.31995 2.5 5.31995C1.255 5.31995 0.25 6.32495 0.25 7.56995C0.25 8.81495 1.255 9.81995 2.5 9.81995C3.0925 9.81995 3.625 9.58745 4.03 9.21245L9.37 12.3324C9.3325 12.4899 9.31 12.6549 9.31 12.8199C9.31 14.0274 10.2925 15.0099 11.5 15.0099C12.7075 15.0099 13.69 14.0274 13.69 12.8199C13.69 11.6124 12.7075 10.6299 11.5 10.6299Z"
        fill="currentColor"
      />
    </svg>
  ),
  favorite: (props) => (
    <svg
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M12.9961 11.3199V9.06989H10.7461V7.56989H12.9961V5.31989H14.4961V7.56989H16.7461V9.06989H14.4961V11.3199H12.9961ZM7.7461 14.3199L5.36484 12.1824C4.46484 11.3699 3.69309 10.6449 3.04959 10.0074C2.40559 9.36989 1.87434 8.76989 1.45584 8.20739C1.03684 7.64489 0.730594 7.10114 0.537094 6.57614C0.343094 6.05114 0.246094 5.50114 0.246094 4.92614C0.246094 3.75114 0.639844 2.77289 1.42734 1.99139C2.21484 1.21039 3.19609 0.819885 4.37109 0.819885C5.02109 0.819885 5.63985 0.954135 6.22735 1.22264C6.81485 1.49164 7.3211 1.87614 7.7461 2.37614C8.1711 1.87614 8.67735 1.49164 9.26485 1.22264C9.85235 0.954135 10.4711 0.819885 11.1211 0.819885C12.1836 0.819885 13.0741 1.14164 13.7926 1.78514C14.5116 2.42914 14.9586 3.18239 15.1336 4.04489C14.9086 3.95739 14.6836 3.89164 14.4586 3.84764C14.2336 3.80414 14.0148 3.78239 13.8023 3.78239C12.5398 3.78239 11.4648 4.22289 10.5773 5.10389C9.68985 5.98539 9.2461 7.05739 9.2461 8.31989C9.2461 8.96988 9.37735 9.58539 9.63985 10.1664C9.90235 10.7479 10.2711 11.2449 10.7461 11.6574C10.5086 11.8699 10.1991 12.1419 9.8176 12.4734C9.4366 12.8044 9.1086 13.0949 8.8336 13.3449L7.7461 14.3199Z"
        fill="currentColor"
      />
    </svg>
  ),
  //   zoom: (props) => (
  //     <svg
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       class={props.class}
  //     >
  //       <path
  //         d="M11.75 5.5H9.25V9.25H5.5V11.75H9.25V15.5H11.75V11.75H15.5V9.25H11.75V5.5Z"
  //         fill="currentColor"
  //       />
  //       <path
  //         d="M10.5 0.5C4.98625 0.5 0.5 4.98625 0.5 10.5C0.5 16.0137 4.98625 20.5 10.5 20.5C12.7187 20.4995 14.8735 19.7568 16.6213 18.39L22.1162 23.885L23.8838 22.1175L18.3888 16.6225C19.7563 14.8746 20.4995 12.7193 20.5 10.5C20.5 4.98625 16.0137 0.5 10.5 0.5ZM10.5 18C6.36375 18 3 14.6363 3 10.5C3 6.36375 6.36375 3 10.5 3C14.6363 3 18 6.36375 18 10.5C18 14.6363 14.6363 18 10.5 18Z"
  //         fill="currentColor"
  //       />
  //     </svg>
  //   ),
  //   remove: (props) => (
  //     <svg
  //       class={props.class}
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g stroke="currentColor" stroke-width="2">
  //         <circle cx="12" cy="12" r="9" />
  //         <path d="m7.5 12h9" />
  //       </g>
  //     </svg>
  //   ),
  //   success: (props) => (
  //     <svg viewBox="0 0 13 13" class={props.class}>
  //       <path
  //         d="M6.5 12.35C9.73087 12.35 12.35 9.73086 12.35 6.5C12.35 3.26913 9.73087 0.65 6.5 0.65C3.26913 0.65 0.65 3.26913 0.65 6.5C0.65 9.73086 3.26913 12.35 6.5 12.35Z"
  //         fill="#428445"
  //         stroke="white"
  //         stroke-width="0.7"
  //       />
  //       <path d="M5.53271 8.66357L9.25213 4.68197" stroke="white" />
  //       <path d="M4.10645 6.7688L6.13766 8.62553" stroke="white" />
  //     </svg>
  //   ),
  //   hamburger: (props) => (
  //     <svg
  //       class={props.class}
  //       viewBox="0 0 40 33"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <rect y="0.5" width="40" height="4" fill="currentColor" />
  //       <rect y="14.5" width="40" height="4" fill="currentColor" />
  //       <rect y="28.5" width="40" height="4" fill="currentColor" />
  //     </svg>
  //   ),
  minus: (props) => (
    <svg
      class={props.class}
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1.904H11.42V0H0V1.904Z" fill="currentColor" />
    </svg>
  ),
  plus: (props) => (
    <svg
      class={props.class}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.648 0V4.9H0V6.832H4.648V11.76H6.748V6.832H11.424V4.9H6.748V0H4.648Z"
        fill="currentColor"
      />
    </svg>
  ),
  heart: (props) => (
    <svg
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M10.5 18.9199L9.05 17.5999C3.9 12.9299 0.5 9.84988 0.5 6.06989C0.5 2.98989 2.92 0.569885 6 0.569885C7.74 0.569885 9.41 1.37989 10.5 2.65989C11.59 1.37989 13.26 0.569885 15 0.569885C18.08 0.569885 20.5 2.98989 20.5 6.06989C20.5 9.84988 17.1 12.9299 11.95 17.6099L10.5 18.9199Z"
        fill="currentColor"
      />
    </svg>
  ),
  search: (props) => (
    <svg
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        d="M13.1649 11.9448H12.3329L12.038 11.6605C13.0701 10.4598 13.6915 8.9011 13.6915 7.20546C13.6915 3.4245 10.6267 0.359711 6.84575 0.359711C3.06479 0.359711 0 3.4245 0 7.20546C0 10.9864 3.06479 14.0512 6.84575 14.0512C8.54139 14.0512 10.1001 13.4298 11.3008 12.3977L11.5851 12.6926V13.5246L16.8511 18.78L18.4203 17.2108L13.1649 11.9448ZM6.84575 11.9448C4.2233 11.9448 2.10639 9.82791 2.10639 7.20546C2.10639 4.58301 4.2233 2.4661 6.84575 2.4661C9.4682 2.4661 11.5851 4.58301 11.5851 7.20546C11.5851 9.82791 9.4682 11.9448 6.84575 11.9448Z"
        fill="currentColor"
      />
    </svg>
  ),
  //   filter: (props) => (
  //     <svg
  //       viewBox="0 0 18 17"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       class={props.class}
  //     >
  //       <rect x="-1" y="1.5" width="20" height="1.5" fill="currentColor" />
  //       <rect x="-1" y="7.5" width="20" height="1.5" fill="currentColor" />
  //       <rect x="-1" y="13.5" width="20" height="1.5" fill="currentColor" />
  //       <rect x="4" y="6.5" width="1.5" height="4" fill="currentColor" />
  //       <rect x="13" y="12.5" width="1.5" height="4" fill="currentColor" />
  //       <rect x="9" y="0.5" width="1.5" height="4" fill="currentColor" />
  //     </svg>
  //   ),
  close: (props) => (
    <svg
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <rect
        x="7.27197"
        width="40"
        height="4"
        transform="rotate(45 7.27197 0)"
        fill="currentColor"
      />
      <rect
        x="4.4436"
        y="28.2843"
        width="40"
        height="4"
        transform="rotate(-45 4.4436 28.2843)"
        fill="currentColor"
      />
    </svg>
  ),
  loader: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      class={props.class}
    >
      <radialGradient
        id="a6"
        cx=".66"
        fx=".66"
        cy=".3125"
        fy=".3125"
        gradientTransform="scale(1.5)"
      >
        <stop offset="0" stop-color="currentColor" />
        <stop offset=".3" stop-color="currentColor" stop-opacity=".9" />
        <stop offset=".6" stop-color="currentColor" stop-opacity=".6" />
        <stop offset=".8" stop-color="currentColor" stop-opacity=".3" />
        <stop offset="1" stop-color="currentColor" stop-opacity="0" />
      </radialGradient>
      <circle
        transform-origin="center"
        fill="none"
        stroke="url(#a6)"
        stroke-width="15"
        stroke-linecap="round"
        stroke-dasharray="200 1000"
        stroke-dashoffset="0"
        cx="100"
        cy="100"
        r="70"
      >
        <animateTransform
          type="rotate"
          attributeName="transform"
          calcMode="spline"
          dur="2"
          values="360;0"
          keyTimes="0;1"
          keySplines="0 0 1 1"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        transform-origin="center"
        fill="none"
        opacity=".2"
        stroke="currentColor"
        stroke-width="15"
        stroke-linecap="round"
        cx="100"
        cy="100"
        r="70"
      />
    </svg>
  ),
  chevronDown: (props) => (
    <svg
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 1.70287L9 10.9999L-6.30269e-08 1.70287L1.49374 0.260986L9 8.01499L16.5063 0.260986L18 1.70287Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronRight: (props) => (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.76236 0L15.1584 12L2.76236 24L0.839844 22.0083L11.1785 12L0.839844 1.99166L2.76236 0Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronLeft: (props) => (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2376 24L0.841586 12L13.2376 -1.68072e-07L15.1602 1.99166L4.82149 12L15.1602 22.0083L13.2376 24Z"
        fill="currentColor"
      />
    </svg>
  ),
} as const

import * as select from '@zag-js/select'
import { normalizeProps, useMachine } from '@zag-js/solid'
import {
  createEffect,
  createMemo,
  createUniqueId,
  For,
  Match,
  onMount,
  Show,
  Switch,
  type Component,
} from 'solid-js'
import type { Option } from '../../helpers'
import { cx } from '../../utils'
import { useStaffContext } from '../../web-components/StaffApp/StaffAppContext'
import { Icon } from '../Icons'

type ZagSelectProps = {
  options: Option[]
  label?: string
  placeholder: string
  selectedPlaceholder?: string
  multiple?: boolean
  //   values: Accessor<Option[]>
  //   setter: Setter<Option[]>
  onChange: (e: Option[]) => void
  onProvideClear?: (clearFunc: (value?: string) => void) => void
  defaultValue?: string[]
  selectClass?: string
}
export const ZagSelect: Component<ZagSelectProps> = (props) => {
  const { shadowRoot, searchFormState } = useStaffContext()

  const [state, send] = useMachine(
    select.machine<Option>({
      id: createUniqueId(),
      multiple: props.multiple,
      collection: select.collection<Option>({ items: [] }),
      getRootNode: () => shadowRoot()?.getRootNode() || document,
      positioning: {
        // strategy: 'fixed',
        placement: 'bottom-start',
      },
      onValueChange(details) {
        const selectedOptions = details.items as Option[]
        // props.setter(selectedOptions)

        props.onChange(selectedOptions)
      },

      value: props.defaultValue,
    })
  )

  const api = createMemo(() => select.connect(state, send, normalizeProps))
  const collection = select.collection<Option>({
    items: [],
    itemToString: (item) => item.label,
    itemToValue: (item) => item.value,
  })

  const checkboxClasses = cx(
    'size-24 rounded-4px border-1px border-gray5 bg-white flex-shrink-0 pointer-events-none'
  )
  // Effect to provide the clear function to the parent
  createEffect(() => {
    if (props.onProvideClear)
      props.onProvideClear((v) => {
        api().clearValue(v)
      })
  })

  onMount(() => {
    api().setCollection(select.collection({ items: props.options }))
  })

  return (
    <div class="flex items-stretch relative">
      <div class="w-full">
        <label {...api().getLabelProps()} class="sr-only">
          {props.label}
        </label>
        <button {...api().getTriggerProps()} class={cx([props.selectClass])}>
          <Switch>
            <Match when={props.selectedPlaceholder}>
              <Show
                when={api().value.length === 0}
                fallback={
                  <>
                    <span>{props.selectedPlaceholder}</span>
                  </>
                }
              >
                <span>{props.placeholder}</span>
              </Show>
            </Match>
            <Match when={props.selectedPlaceholder === undefined}>
              <span>{api().valueAsString || 'Select option'}</span>
            </Match>
          </Switch>
          <span>
            <Icon icon="chevronDown" class={'size-12'} />
          </span>
        </button>
      </div>

      <div class="absolute left-0 bottom-0 translate-y-full z-50 ">
        <div
          class="bg-white  rounded-4px border-blue border-opacity-10 border-1px"
          {...api().getPositionerProps()}
        >
          <ul {...api().getContentProps()}>
            <For each={props.options}>
              {(item) => (
                <li
                  {...api().getItemProps({ item })}
                  class="px-15 py-10 cursor-pointer group hover:bg-blue hover:bg-opacity-10 data-[highlighted]:bg-blue data-[highlighted]:bg-opacity-10"
                >
                  <div class="flex gap-20 justify-between items-center whitespace-nowrap">
                    <span class="type-mobile-p3-medium md:type-p3-medium text-black">
                      {item.label}
                    </span>
                    {/* <span {...api().getItemIndicatorProps({ item })}>✓</span> */}
                    <input
                      type="checkbox"
                      tabIndex={-1}
                      checked={false}
                      class={cx([
                        checkboxClasses,
                        'group-data-[state=unchecked]:inline-block hidden',
                      ])}
                    />
                    <input
                      type="checkbox"
                      tabIndex={-1}
                      checked={true}
                      class={cx([
                        checkboxClasses,
                        'group-data-[state=checked]:inline-block hidden',
                      ])}
                    />
                  </div>
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
    </div>
  )
}

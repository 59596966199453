import { z } from 'zod'

export const FeaturedImageSchema = z.object({
  featured_image: z
    .object({
      id: z.number(),
      url: z.string(),
      width: z.number(),
      height: z.number(),
      alt: z.string(),
      caption: z.string(),
    })
    .nullish(),
})

const TaxonomyTermSchema = z.object({
  id: z.number(),
  count: z.number(),
  description: z.string(),
  link: z.string(),
  name: z.string(),
  slug: z.string(),
  taxonomy: z.string(),
  parent: z.number().nullish(),
  // meta: z.array(z.unknown()),
})

export const TaxonomyRestSchema = z.array(TaxonomyTermSchema)

export type WpRestOrdering = {
  order?: 'asc' | 'desc'
  orderby?: 'title' | 'date'
}

import { z } from 'zod'

const AcfImageArraySchema = z
  .object({
    alt: z.string(),
    url: z.string().url(),
    sizes: z.object({
      medium_large: z.string().url(),
      'medium_large-width': z.number(),
      'medium_large-height': z.number(),
    }),
  })
  .or(z.literal(false))
  .transform((val) => {
    type ImageType = Exclude<typeof val, false>
    if (val === false) {
      const placeholder: ImageType = {
        url: 'https://placehold.co/300x300?text=image+not+set',
        alt: 'placeholder image',
        sizes: {
          medium_large: 'https://placehold.co/300x300?text=image+not+set',
          'medium_large-width': 300,
          'medium_large-height': 300,
        },
      }
      return placeholder
    }
    return val
  })

const SocialsSchema = z.object({
  facebook: z.string().nullish(),
  twitter: z.string().nullish(),
  linkedin: z.string().nullish(),
  instagram: z.string().nullish(),
})

export const StaffAcfsSchema = z.object({
  position: z.string().nullish(),
  hover_art: z.string().or(z.literal(false)),
  department: z
    .array(
      z.object({
        term_id: z.number(),
        name: z.string(),
        slug: z.string(),
        term_group: z.number(),
        term_taxonomy_id: z.number(),
        taxonomy: z.string(),
        description: z.string(),
        parent: z.number(),
        count: z.number(),
        filter: z.string(),
        // term_order: z.string(),
      })
    )
    .or(z.literal(false)),
  headshot: AcfImageArraySchema,
  headshot_hover: AcfImageArraySchema,
  phone_number: z.string().nullish(),
  socials: SocialsSchema.transform((val) => {
    return {
      ...val,
      array: [
        {
          name: 'facebook',
          url: val.facebook,
        },
        {
          name: 'twitter',
          url: val.twitter,
        },
        {
          name: 'linkedin',
          url: val.linkedin,
        },
        {
          name: 'instagram',
          url: val.instagram,
        },
      ] as const,
    }
  }),
  email: z.string().nullish(),
  bio: z.string(),
})

export const StaffSchema = z
  .object({
    id: z.number(),
    title: z.object({
      rendered: z.string(),
    }),

    acf: z.object({
      staff: StaffAcfsSchema,
    }),
  })
  // .merge(FeaturedImageSchema)
  .passthrough()

export type SingleStaff = z.infer<typeof StaffSchema>
export const StaffListSchema = z.array(StaffSchema)

export type StaffList = z.infer<typeof StaffListSchema>

import { attachDevtoolsOverlay } from '@solid-devtools/overlay'
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query'
import { SolidQueryDevtools } from '@tanstack/solid-query-devtools'
import { getCurrentElement } from 'solid-element'
import { createEffect, createSignal, ErrorBoundary, type Component } from 'solid-js'
import { logger } from '../../utils'
import { StaffAppContextProvider } from './StaffAppContext'
import { StaffGrid } from './StaffGrid'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: process.env.NODE_ENV === 'development' ? 0 : 5 * 60 * 1000, // 5 minutes,
      // gcTime: 1000 * 60 * 60, // 1 hour
    },
  },
})
type StaffAppProps = {
  //
}
export const StaffApp: Component<StaffAppProps> = () => {
  const currentEl = getCurrentElement()

  attachDevtoolsOverlay({
    defaultOpen: false,
    noPadding: true,
  })

  const [shadowRoot, setShadowRoot] = createSignal<ShadowRoot>()

  createEffect(() => {
    const shadow = currentEl?.shadowRoot

    if (shadow) {
      setShadowRoot(shadow)
    }
  })

  return (
    <div class="mml-onemagnify-plugin">
      <QueryClientProvider client={queryClient}>
        <SolidQueryDevtools
          shadowDOMTarget={shadowRoot()}
          initialIsOpen={false}
          buttonPosition="top-right"
        />

        <StaffAppContextProvider shadowRoot={shadowRoot()}>
          <ErrorBoundary
            fallback={(e) => {
              logger(e, 'error')
              return <>{JSON.stringify(e)}</>
            }}
          >
            <>
              <StaffGrid />
            </>
          </ErrorBoundary>
        </StaffAppContextProvider>
      </QueryClientProvider>
    </div>
  )
}

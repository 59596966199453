/* eslint-disable solid/no-innerhtml */
import { Component, createResource, createSignal, For, Show, Suspense } from 'solid-js'

import ky from 'ky'
import { render } from 'solid-js/web'
import { z } from 'zod'
import { LoadingSpinner } from '../LegacyIcons'

// Define types for company taxonomy and company type
export type companyTaxonomy = 'bap_category' | 'yp_category'
export type companyType = 'bap_company' | 'yp_company'

// Schema definitions - matching the original implementation exactly
const CompanyContactSchema = z.union([
  z.object({
    office_name: z.string().nullish(),
    contact_name: z.string(),
    contact_title: z.string(),
    address_1: z.string(),
    address_2: z.string(),
    city: z.string(),
    state: z.string(),
    zip_code: z.string(),
    phone: z.string(),
    fax: z.string(),
    email: z.string(),
  }),
  z.object({
    office_name: z.string(),
    contact_name: z.string(),
    contact_title: z.string(),
    address_1: z.string(),
    address_2: z.string(),
    city: z.string(),
    state: z.string(),
    zip_code: z.string(),
    phone: z.string(),
    fax: z.string(),
    email: z.string(),
  }),
])

// Match the original schema structure
const TermSchema = z.object({
  id: z.number(),
  count: z.number(),
  description: z.string(),
  link: z.string(),
  name: z.string(),
  slug: z.string(),
  taxonomy: z.string(),
  meta: z.array(z.unknown()),
  acf: z.array(z.unknown()),
  _links: z.object({
    self: z.array(z.object({ href: z.string() })),
    collection: z.array(z.object({ href: z.string() })),
    about: z.array(z.object({ href: z.string() })),
    'wp:post_type': z.array(z.object({ href: z.string() })),
    curies: z.array(z.object({ name: z.string(), href: z.string(), templated: z.boolean() })),
  }),
})

const TermsArraySchema = z.array(TermSchema)

export type Term = z.infer<typeof TermSchema>

// Match the original schema structure exactly
const CompanySchema = z.object({
  id: z.number(),
  date: z.string(),
  date_gmt: z.string(),
  guid: z.object({ rendered: z.string() }),
  modified: z.string(),
  modified_gmt: z.string(),
  slug: z.string(),
  status: z.string(),
  type: z.string(),
  link: z.string(),
  title: z.object({
    rendered: z.string(),
  }),
  template: z.string(),
  acf: z.object({
    bap_company: z.boolean(),
    yp_company: z.boolean(),
    website: z.string(),
    bap_description: z.string(),
    bap_contact_info: z.array(CompanyContactSchema).or(z.literal(false)).nullish(),
    bap_categories: z.array(z.number()).or(z.boolean()),
    bap_type: z.union([z.string(), z.boolean()]),
    yp_description: z.string(),
    yp_contact_info: z.array(CompanyContactSchema).or(z.literal(false)).nullish(),
    yp_other_office_text: z.string(),
    yp_categories: z.array(z.number()).or(z.boolean()),
    yp_service_area: z.string(),
  }),
  _links: z.object({
    self: z.array(z.object({ href: z.string() })),
    collection: z.array(z.object({ href: z.string() })),
    about: z.array(z.object({ href: z.string() })),
    'acf:term': z
      .array(
        z.object({
          embeddable: z.boolean(),
          taxonomy: z.string(),
          href: z.string(),
        })
      )
      .nullish(),
    'wp:attachment': z.array(z.object({ href: z.string() })),
    'wp:term': z.array(
      z.object({
        taxonomy: z.string(),
        embeddable: z.boolean(),
        href: z.string(),
      })
    ),
    curies: z.array(z.object({ name: z.string(), href: z.string(), templated: z.boolean() })),
  }),
})

// Define array schemas for validation
const CompaniesArraySchema = z.array(CompanySchema)

export type Company = z.infer<typeof CompanySchema>
export type CompanyContact = z.infer<typeof CompanyContactSchema>

// Define the wpQuery API for fetching data
const wpQueryApiBase = `${window.location.origin}/wp-json/wp/v2`

// API query functions
const wpQuery = {
  async getCompanies({
    query,
  }: {
    query: { meta_key: companyType; meta_value: boolean; orderby: string; order: string }
  }) {
    try {
      const queryParams = new URLSearchParams({
        meta_key: query.meta_key,
        meta_value: String(query.meta_value),
        orderby: query.orderby,
        order: query.order,
      }).toString()

      // Fetch data and validate with Zod
      const rawResponse = await ky.get(`${wpQueryApiBase}/company?${queryParams}`).json()
      const validatedResponse = CompaniesArraySchema.safeParse(rawResponse)

      if (validatedResponse.success) {
        return validatedResponse.data
      } else {
        console.error('Validation error:', validatedResponse.error)
        // Return raw response if validation fails but data is an array
        if (Array.isArray(rawResponse)) {
          return rawResponse as Company[]
        }
        return [] as Company[]
      }
    } catch (error) {
      console.error('Error fetching companies:', error)
      return [] as Company[]
    }
  },

  async getCompanyTaxonomies({ path }: { path: companyTaxonomy }) {
    try {
      const rawResponse = await ky.get(`${wpQueryApiBase}/${path}`).json()
      const validatedResponse = TermsArraySchema.safeParse(rawResponse)

      if (validatedResponse.success) {
        return validatedResponse.data
      } else {
        console.error(`Validation error for ${path}:`, validatedResponse.error)
        // Return raw response if validation fails but data is an array
        if (Array.isArray(rawResponse)) {
          return rawResponse as Term[]
        }
        return [] as Term[]
      }
    } catch (error) {
      console.error(`Error fetching ${path}:`, error)
      return [] as Term[]
    }
  },

  async getCompany({ id }: { id: number }) {
    try {
      const rawResponse = await ky.get(`${wpQueryApiBase}/company/${id}`).json()
      const validatedResponse = CompanySchema.safeParse(rawResponse)

      if (validatedResponse.success) {
        return validatedResponse.data
      } else {
        console.error(`Validation error for company ${id}:`, validatedResponse.error)
        // Return raw response if validation fails but has required fields
        if (rawResponse && typeof rawResponse === 'object' && 'id' in rawResponse) {
          return rawResponse as Company
        }
        return null
      }
    } catch (error) {
      console.error(`Error fetching company ${id}:`, error)
      return null
    }
  },

  async getCompaniesByTerm({
    query,
    path,
  }: {
    query: { [key: string]: any; orderby: string; per_page: number; order: string }
    path: string
  }) {
    try {
      const queryParams = new URLSearchParams()

      // Add all query parameters
      Object.entries(query).forEach(([key, value]) => {
        queryParams.set(key, String(value))
      })

      const rawResponse = await ky.get(`${wpQueryApiBase}/${path}?${queryParams.toString()}`).json()
      const validatedResponse = CompaniesArraySchema.safeParse(rawResponse)

      if (validatedResponse.success) {
        return validatedResponse.data
      } else {
        console.error('Validation error for companies by term:', validatedResponse.error)
        // Return raw response if validation fails but data is an array
        if (Array.isArray(rawResponse)) {
          return rawResponse as Company[]
        }
        return [] as Company[]
      }
    } catch (error) {
      console.error('Error fetching companies by term:', error)
      return [] as Company[]
    }
  },
}

async function fetchCompanies(companyType: companyType) {
  try {
    const data = await wpQuery.getCompanies({
      query: {
        meta_key: companyType,
        meta_value: true,
        orderby: 'title',
        order: 'asc',
      },
    })
    return data
  } catch (error) {
    console.error(error)
    return [] as Company[]
  }
}

async function fetchCategories(companyTaxonomy: companyTaxonomy) {
  try {
    const data = await wpQuery.getCompanyTaxonomies({ path: companyTaxonomy })
    return data
  } catch (error) {
    console.error(error)
    return [] as Term[]
  }
}

// Company component
type CompanyProps = {
  companyId: number
  companyType: companyType
  categories: Term[]
  class?: string
}

function Company(props: CompanyProps) {
  const [company] = createResource(
    () => props.companyId,
    async (id) => {
      try {
        return await wpQuery.getCompany({ id })
      } catch (error) {
        console.error(`Error fetching company ${id}:`, error)
        return null
      }
    }
  )

  return (
    <div class={props.class}>
      <Show when={company()} fallback={<div>Loading company information...</div>}>
        <Show when={props.companyType === 'bap_company'}>
          <BapCompany categories={props.categories} company={company() as Company} />
        </Show>
        <Show when={props.companyType === 'yp_company'}>
          <YpCompany categories={props.categories} company={company() as Company} />
        </Show>
      </Show>
    </div>
  )
}

// Helper function to validate arrays
const validateIfArray = (arr: any) => {
  return Array.isArray(arr) && arr.length > 0
}

// Helper function to safely map over arrays that might be boolean or false
const safeArrayMap = <T, U>(
  arr: T[] | boolean | false | null | undefined,
  mapFn: (item: T, index: number) => U
): U[] => {
  if (Array.isArray(arr)) {
    return arr.map(mapFn)
  }
  return []
}

// BapCompany component
type BapCompanyProps = {
  company: Company
  categories: Term[]
}

function BapCompany(props: BapCompanyProps) {
  return (
    <div>
      <div class="flex flex-col space-y-2">
        <h3 class="text-xl font-bold">
          <span class="sr-only">Company Name</span>
          <span innerHTML={props.company?.title.rendered || 'Company Name'} />
          {props.company?.acf?.bap_type && ` - ${props.company?.acf?.bap_type}`}
        </h3>

        {/* Categories */}
        <Show when={validateIfArray(props.company?.acf?.bap_categories)}>
          <div class="flex flex-wrap gap-2">
            <div class="font-bold">Categories:</div>
            {safeArrayMap(props.company?.acf?.bap_categories, (categoryId: number) => (
              <div class="text-rebrand-blue">
                {props.categories.find((cat) => cat.id === categoryId)?.name || 'Unknown Category'}
              </div>
            ))}
          </div>
        </Show>

        {/* Contact Info */}
        <Show when={props.company?.acf.bap_contact_info}>
          <div class="font-bold">Contact:</div>
          {safeArrayMap(props.company?.acf.bap_contact_info, (contact: CompanyContact) => (
            <CompanyContact info={contact} />
          ))}
        </Show>

        {/* Website */}
        <Show when={props.company?.acf?.website}>
          <div>
            <span class="font-bold">Website: </span>
            <a
              href={props.company?.acf?.website || ''}
              target="_blank"
              rel="noopener noreferrer"
              class="text-rebrand-blue"
            >
              {props.company?.acf?.website}
            </a>
          </div>
        </Show>

        {/* Description */}
        <Show when={props.company?.acf?.bap_description}>
          <div class="font-bold">Description:</div>
          <div innerHTML={props.company?.acf?.bap_description || ''} />
        </Show>
      </div>
    </div>
  )
}

// YpCompany component
type YpCompanyProps = {
  company: Company
  categories: Term[]
}

function YpCompany(props: YpCompanyProps) {
  return (
    <div>
      <div class="flex flex-col space-y-2">
        <h3 class="text-xl font-bold">
          <span class="sr-only">Company Name</span>
          <span innerHTML={props.company?.title.rendered || 'Company Name'} />
        </h3>

        {/* Categories */}
        <Show when={validateIfArray(props.company?.acf?.yp_categories)}>
          <div class="flex flex-wrap gap-2">
            <div class="font-bold">Categories:</div>
            {safeArrayMap(props.company?.acf?.yp_categories, (categoryId: number) => (
              <div class="text-rebrand-blue">
                {props.categories.find((cat) => cat.id === categoryId)?.name || 'Unknown Category'}
              </div>
            ))}
          </div>
        </Show>

        {/* Contact Info */}
        <Show when={props.company?.acf.yp_contact_info}>
          <div class="font-bold">Contact:</div>
          {safeArrayMap(props.company?.acf.yp_contact_info, (contact: CompanyContact) => (
            <CompanyContact info={contact} />
          ))}
        </Show>

        {/* Website */}
        <Show when={props.company?.acf?.website}>
          <div>
            <span class="font-bold">Website: </span>
            <a
              href={props.company?.acf?.website || ''}
              target="_blank"
              rel="noopener noreferrer"
              class="text-rebrand-blue"
            >
              {props.company?.acf?.website}
            </a>
          </div>
        </Show>

        {/* Service Area */}
        <Show when={props.company.acf.yp_service_area}>
          <div>
            <span class="font-bold">Service Area: </span>
            {props.company.acf.yp_service_area}
          </div>
        </Show>

        {/* Description */}
        <Show when={props.company?.acf?.yp_description}>
          <div class="font-bold">Description:</div>
          <div innerHTML={props.company?.acf?.yp_description || ''} />
        </Show>

        {/* Other Office Text */}
        <Show when={props.company.acf.yp_other_office_text}>
          <div class="font-bold">{props.company.acf.yp_other_office_text}</div>
        </Show>
      </div>
    </div>
  )
}

// CompanyContact component
type CompanyContactProps = {
  info: CompanyContact
}

function CompanyContact(props: CompanyContactProps) {
  return (
    <div class="ml-4">
      {props.info.office_name && <div>{props.info.office_name}</div>}
      <div>{props.info.contact_name}</div>
      <div>{props.info.contact_title}</div>
      <div>{props.info.address_1}</div>
      {props.info.address_2 && props.info.address_2.trim() !== '' && (
        <div>{props.info.address_2}</div>
      )}
      <div>
        {props.info.city}, {props.info.state} {props.info.zip_code}
      </div>
      <div>Phone: {props.info.phone}</div>
      {props.info.fax && props.info.fax.trim() !== '' && <div>Fax: {props.info.fax}</div>}
      <div>
        Email:{' '}
        <a href={`mailto:${props.info.email}`} class="text-rebrand-blue">
          {props.info.email}
        </a>
      </div>
    </div>
  )
}

// FormBapByCompany component
type FormBapByCompanyProps = {
  companies: Company[] | undefined
  updateHandler: updateHandler
  activeForm: formTypes | undefined
}

function FormBapByCompany(props: FormBapByCompanyProps) {
  let companySelect: HTMLSelectElement

  createSignal(() => {
    if (props.activeForm === 'category') {
      companySelect.selectedIndex = 0
    }
  })

  return (
    <form action="" class="">
      <label class="mb-2 block font-bold" for="bap-companies">
        Search by Company
      </label>
      <div class="flex max-md:max-w-md max-md:flex-wrap">
        <span class="relative flex-initial max-md:w-full">
          <select
            name="bap-companies"
            id="bap-companies"
            class="max-md:border-b-0 md:border-r-0 h-16 max-w-full rounded-none border border-black p-4 pr-10 max-md:w-full"
            ref={(e) => (companySelect = e)}
          >
            <option value="">Select Company</option>
            <For each={props.companies}>
              {(company) => <option value={company.id} innerHTML={company.title.rendered} />}
            </For>
          </select>
        </span>
        <button
          class="button-square max-md:w-full"
          onClick={(e) => {
            e.preventDefault()

            if (companySelect.value) {
              props.updateHandler({
                companies: [Number(companySelect.value)],
                form: 'company',
              })
            }
          }}
        >
          GO
        </button>
      </div>
    </form>
  )
}

// FormCompanyByCategory component
type fetchCompaniesByTermParams = {
  companyTaxonomy: companyTaxonomy
  termId: number
}

type FormCompanyByCategoryProps = {
  terms: Term[] | undefined
  updateHandler: updateHandler
  companyTaxonomy: companyTaxonomy
  activeForm: formTypes | undefined
}

function FormCompanyByCategory(props: FormCompanyByCategoryProps) {
  const [selectedCategory, setSelectedCategory] = createSignal<fetchCompaniesByTermParams>()
  const [companies] = createResource(selectedCategory, async (params) => {
    try {
      let query: Record<string, any> = {}
      if (params.companyTaxonomy === 'yp_category') {
        query = { yp_category: params.termId }
      }
      if (params.companyTaxonomy === 'bap_category') {
        query = { bap_category: params.termId }
      }

      const response = await wpQuery.getCompaniesByTerm({
        query: { orderby: 'title', per_page: 100, order: 'asc', ...query },
        path: 'company',
      })

      return response.map((c) => c.id)
    } catch (error) {
      console.error(error)
      return []
    }
  })

  let companySelect: HTMLSelectElement

  createSignal(() => {
    const companiesValue = companies()
    if (!companiesValue) return
    props.updateHandler({ companies: companiesValue, form: 'category' })
  })

  createSignal(() => {
    if (props.activeForm === 'company') {
      companySelect.selectedIndex = 0
    }
  })

  return (
    <form action="">
      <label class="mb-2 block font-bold" for="bap-categories">
        Search by Category
      </label>
      <div class="flex max-md:max-w-md max-md:flex-wrap">
        <span class="relative flex-initial max-md:w-full">
          <select
            name="bap-categories"
            id="bap-categories"
            class="max-md:border-b-0 md:border-r-0 h-16 max-w-full rounded-none border border-black p-4 pr-10 max-md:w-full"
            ref={(e) => (companySelect = e)}
          >
            <option value="">Select Category</option>
            <For each={props.terms}>
              {(category) => <option value={category.id} innerHTML={category.name} />}
            </For>
          </select>
        </span>
        <button
          class="button-square max-md:w-full"
          onClick={(e) => {
            e.preventDefault()
            if (companySelect.value) {
              setSelectedCategory({
                companyTaxonomy: props.companyTaxonomy,
                termId: Number(companySelect.value),
              })
            }
          }}
        >
          GO
        </button>
      </div>
    </form>
  )
}

export type formTypes = 'company' | 'category'
type FormAppProps = {
  companyType: companyType
  companyTaxonomy: companyTaxonomy
}
type updateHandlerProps = { companies: number[]; form: formTypes }
export type updateHandler = (props: updateHandlerProps) => void

export const BapFormApp: Component<FormAppProps> = (props) => {
  const [companyIds, setCompanyIds] = createSignal<number[] | undefined>()
  const [companies] = createResource(props.companyType, fetchCompanies)
  const [categories] = createResource(props.companyTaxonomy, fetchCategories)
  const [activeForm, setActiveForm] = createSignal<formTypes>()

  const updateHandler: updateHandler = (params) => {
    setCompanyIds(params.companies)
    setActiveForm(params.form)
  }

  return (
    <div class="">
      <div class="mb-8 space-y-4">
        <Show when={companies()}>
          <FormBapByCompany
            companies={companies()}
            updateHandler={updateHandler}
            activeForm={activeForm()}
          />
        </Show>
        <Show when={categories()} keyed>
          {(cat) => (
            <FormCompanyByCategory
              companyTaxonomy={props.companyTaxonomy}
              terms={cat}
              updateHandler={updateHandler}
              activeForm={activeForm()}
            />
          )}
        </Show>
      </div>
      <div class="divide-rebrand-blue divide-y-1 bg-paleBlue not-prose relative rounded-20px px-6 md:px-9">
        <Suspense
          fallback={
            <div class="min-h-[9rem]">
              <div class="absolute inset-0 flex items-center justify-center heir-svg:h-24 heir-svg:w-24">
                <LoadingSpinner />
              </div>
            </div>
          }
        >
          <Show when={categories.state === 'ready' && categories()} keyed>
            {(cats) => (
              <Show when={companyIds()} keyed>
                {(companies) => (
                  <For each={companies}>
                    {(company) => (
                      <Company
                        class="mb-8 pb-7 pt-1"
                        companyType={props.companyType}
                        companyId={company}
                        categories={cats}
                      />
                    )}
                  </For>
                )}
              </Show>
            )}
          </Show>
        </Suspense>
      </div>
    </div>
  )
}

// Render the app
export function renderBapFormApp(
  elementId: string,
  companyTaxonomy: companyTaxonomy,
  companyType: companyType
) {
  const appTarget = document.getElementById(elementId)
  if (appTarget) {
    render(
      () => <BapFormApp companyTaxonomy={companyTaxonomy} companyType={companyType} />,
      appTarget as HTMLElement
    )
  } else {
    console.error(`Element with ID "${elementId}" not found for rendering BapFormApp`)
  }
}

export const loadBapApp = () => {
  // Auto-initialize for BAP app
  document.addEventListener('DOMContentLoaded', () => {
    const bapAppTarget = document.getElementById('bap-app')
    if (bapAppTarget) {
      renderBapFormApp('bap-app', 'bap_category', 'bap_company')
    }

    const ypAppTarget = document.getElementById('yellowpages-app')
    if (ypAppTarget) {
      renderBapFormApp('yellowpages-app', 'yp_category', 'yp_company')
    }
  })
}

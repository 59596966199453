import axios from 'axios'

import { z } from 'zod'
import type { ActiveFiltersType } from './context/context'
import { qs } from './helpers'
import { WpPageSchema } from './schema/WpPageSchema'
import {
  AppSettingsSchema,
  type CaseStudiesResponse,
  CaseStudiesResponseSchema,
  CaseStudyFiltersResponseSchema,
} from './types'
import { CaseStudyListSchema, SingleCaseStudySchema } from './types/SingleCaseStudySchema'
import { StaffListSchema } from './types/StaffTypes'
import { TaxonomyRestSchema, type WpRestOrdering } from './types/WordPress'
const apiPath = window.location.origin

export const apiAxios = axios.create({
  baseURL: apiPath,
  // paramsSerializer: (params) => {
  //   return qs.stringify(params, {
  //     arrayFormat: 'comma',
  //     skipEmptyString: true,
  //     skipNull: true,
  //   })
  // },
})

const REST_ENDPOINTS = {
  caseStudy: '/wp-json/wp/v2/case-study',
  caseStudies: '/wp-json/wp/v2/case-study',
  pages: '/wp-json/wp/v2/pages',
  page: (id: number) => `/wp-json/wp/v2/pages/${id}`,
  caseStudySettings: '/wp-json/om/v1/case-study-app/settings',
  caseStudyFilters: '/wp-json/custom/v1/case-study-filters',
  caseStudiesCustom: '/wp-json/custom/v1/case-studies',
}

export const useWpApi = {
  fetchCaseStudyFilters: async (filters: ActiveFiltersType, searchQuery: string) => {
    const { location_population, ...filteredFilters } = filters

    const res = await apiAxios.get(REST_ENDPOINTS.caseStudyFilters, {
      params: {
        tax_queries: filteredFilters,
        location_population: location_population,
        search: searchQuery,
      },
    })

    return CaseStudyFiltersResponseSchema.parse(res.data)
  },

  //get app settings
  getAppSettings: async () => {
    const res = await apiAxios.get(REST_ENDPOINTS.caseStudySettings)

    return AppSettingsSchema.parse(res.data)
  },

  // Fetch case studies from WordPress REST API with filters and search
  fetchCaseStudies: async (
    filters: Record<string, number[] | string[]>,
    searchQuery: string,
    page: number
  ): Promise<CaseStudiesResponse> => {
    const res = await apiAxios.get(REST_ENDPOINTS.caseStudiesCustom, {
      params: {
        ...filters,
        search: searchQuery,
        page: page.toString(),
      },
    })
    // console.log(res.request)
    return CaseStudiesResponseSchema.parse(res.data)
  },
  fetchCaseStudy: async (slug: string) => {
    const res = await apiAxios.get(REST_ENDPOINTS.caseStudy, {
      params: {
        slug: slug,
      },
    })
    const caseStudy = z.array(SingleCaseStudySchema).parse(res.data)

    if (caseStudy.length === 0) {
      throw new Error('Case study not found')
    }

    return caseStudy[0] // Only one case study should
  },
  fetchCaseStudyList: async (args: {
    ids: number[]
    search?: string
    page?: number
    noPaging?: boolean
  }) => {
    const res = await apiAxios.get(
      qs.stringifyUrl(
        {
          url: REST_ENDPOINTS.caseStudies,
          query: {
            include: args.ids,
            per_page: args.noPaging === true ? 100 : 5,
            search: args.search,
            page: args.noPaging === true ? undefined : args.page ? args.page.toString() : undefined,
          },
        },
        {
          arrayFormat: 'comma',
          skipEmptyString: true,
        }
      )
    )

    const totalPages = parseInt(res.headers['x-wp-totalpages'] || '1', 10)

    if (args.ids.length === 0) {
      return { totalPages: 0, posts: [] }
    }

    return {
      totalPages,
      posts: CaseStudyListSchema.parse(res.data),
    }
  },

  fetchPageById: async (id: number) => {
    const res = await apiAxios.get(REST_ENDPOINTS.page(id))

    return WpPageSchema.parse(res.data)
  },

  fetchStaff: async (
    args: {
      search?: string
      department?: number[]
      page: number
    } & WpRestOrdering
  ) => {
    const res = await apiAxios.get('/wp-json/wp/v2/staff', {
      params: {
        per_page: 16,
        page: args.page,
        search: args.search,
        'staff-department': args.department || undefined,
        order: args.order || 'desc',
        orderby: args.orderby || 'title',
      },
    })

    const data = StaffListSchema.parse(res.data)
    // return data
    return {
      totalPages: res.headers['x-wp-totalpages'] as number,
      currentPage: args.page,
      results: data,
    }
  },
  fetchStaffDepartments: async (args: { excludeEmpty?: boolean }) => {
    const res = await apiAxios.get('/wp-json/wp/v2/staff-department', {
      params: {
        per_page: 100,
      },
    })
    const data = TaxonomyRestSchema.parse(res.data)

    if (args.excludeEmpty) {
      return data.filter((item) => item.count > 0)
    }
    return data
  },
}

import { customElement, noShadowDOM } from 'solid-element'
import type { ComponentProps } from 'solid-js'
import { CaseStudiesApp } from './CaseStudiesApp'
import { loadBapApp } from './components/BapFormApp/BapFormApp'
import styles from './tailwind-web-components.css?inline'
// import './index.css'
import { DesktopHeader } from './components/MainNavMenu/DesktopMenu'
import { MobileHeader } from './components/MainNavMenu/MobileMenu'
import { loadSwipers } from './components/swiper-js/swiper'
import { KyMobileDropDownToggle } from './web-components/KyMobileDropDownToggle'
import { StaffApp } from './web-components/StaffApp/StaffApp'

customElement<ComponentProps<typeof DesktopHeader>>(
  'ky-desktop-header',
  {
    logo: '',
  },
  (props) => {
    noShadowDOM()
    return DesktopHeader(props)
  }
)

customElement<ComponentProps<typeof MobileHeader>>(
  'ky-mobile-header',
  {
    logo: '',
  },
  (props) => {
    noShadowDOM()
    return MobileHeader(props)
  }
)

customElement<ComponentProps<typeof KyMobileDropDownToggle>>(
  'ky-mobile-drop-down-toggle',
  {},
  (props) => {
    noShadowDOM()

    return <KyMobileDropDownToggle {...props} />
  }
)

function injectStyles(host: HTMLElement) {
  if (host.shadowRoot) {
    // If shadowRoot already exists, inject styles immediately
    const style = document.createElement('style')
    style.textContent = styles
    host.shadowRoot.appendChild(style)
  } else {
    // If shadowRoot doesn't exist yet, use MutationObserver to wait for it
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && host.shadowRoot) {
          const style = document.createElement('style')
          style.textContent = styles
          host.shadowRoot.appendChild(style)
          observer.disconnect() // Stop observing once styles are injected
        }
      })
    })

    // Start observing the host element for childList changes
    observer.observe(host, { childList: true })
  }
}

customElement<ComponentProps<typeof CaseStudiesApp>>(
  'case-studies-app',
  {
    apiBaseUrl: window.location.origin,
  },
  (props) => {
    noShadowDOM()
    return CaseStudiesApp(props)
  }
)

customElement<ComponentProps<typeof StaffApp>>('onemagnify-staff-app', {}, (props) => {
  // noShadowDOM()
  return StaffApp(props)
})

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector(['case-studies-app', 'onemagnify-staff-app'].join(','))

  if (element) {
    injectStyles(element as HTMLElement)
  }
})

loadSwipers()

loadBapApp()

import { TextField } from '@kobalte/core/text-field'
import { createQuery } from '@tanstack/solid-query'
import {
  createEffect,
  createSignal,
  For,
  Match,
  on,
  Show,
  Switch,
  type Component,
  type JSX,
} from 'solid-js'
import { produce } from 'solid-js/store'
import { useWpApi } from '../../api'
import { Icon } from '../../components/Icons'
import { ZagSelect } from '../../components/zag/ZagSelect'
import { type Option } from '../../helpers'
import { cx } from '../../utils'
import { useStaffContext } from './StaffAppContext'
export type SearchForm = {
  query: string
  departments: string[]
}

type StaffSearchProps = {
  placeholder: string
  searchButtonText: string
}
export const StaffSearch: Component<StaffSearchProps> = (props) => {
  const { searchFormState, setSearchFormState } = useStaffContext()

  let clearSelectValue: (message?: string) => void

  const handleClearFunction = (clearFunc: (value?: string) => void) => {
    clearSelectValue = clearFunc
  }

  const staffDepartmentQuery = createQuery(() => ({
    queryKey: ['staffDepartments'],
    queryFn: async () => {
      return await useWpApi.fetchStaffDepartments({
        excludeEmpty: true,
      })
    },
    // gcTime: 1000 * 60 * 60, // 1 hour
    staleTime: process.env.NODE_ENV === 'development' ? 0 : 5 * 60 * 1000, // 5 minutes,

    throwOnError: true, // Throw an error if the query fails
  }))

  const [departmentValues, setDepartmentValues] = createSignal<Option[]>([])

  const handleSubmit: JSX.EventHandler<HTMLFormElement, Event> = (e) => {
    e.preventDefault()
  }

  createEffect(
    on(
      departmentValues,
      (departments) => {
        setSearchFormState('departments', departments)
      },
      { defer: true }
    )
  )

  const handleClearFilters = () => {
    clearSelectValue()
  }

  return (
    <Switch>
      <Match when={staffDepartmentQuery.isPending}>
        <p>Loading...</p>
      </Match>
      <Match when={staffDepartmentQuery.isRefetching}>
        <p>Refetching...</p>
      </Match>
      <Match when={staffDepartmentQuery.isError}>
        <p>Error: {staffDepartmentQuery.error?.message}</p>
      </Match>
      <Match
        when={staffDepartmentQuery.isSuccess && staffDepartmentQuery.data}
        keyed
      >
        {(data) => (
          <div>
            <div class="mb-20">
              <div class="flex flex-col gap-10 md:gap-20 md:flex-row items-stretch">
                <ZagSelect
                  label="Department Filter"
                  placeholder="ALL STAFF"
                  onProvideClear={handleClearFunction}
                  selectedPlaceholder="DEPARTMENT"
                  // values={departmentValues}
                  multiple={true}
                  onChange={(e) => {
                    setDepartmentValues(e)
                  }}
                  // values={departmentValues}
                  options={data.map((dept) => {
                    return { label: dept.name, value: String(dept.id) }
                  })}
                  selectClass={cx(
                    'rounded-4px flex gap-20 w-full bg-blue bg-opacity-10 type-h7-semibold-all-caps text-blue px-14 py-12 leading-none items-center md:w-240 justify-between h-full'
                  )}
                />

                <form
                  class="flex flex-col gap-10 md:gap-20 md:flex-row items-stretch w-full"
                  onSubmit={handleSubmit}
                  onReset={handleSubmit}
                >
                  <div class="relative w-full">
                    <TextField
                      value={searchFormState.query}
                      onChange={(e) => {
                        setSearchFormState('query', e)
                      }}
                      validationState={
                        searchFormState.query.length > 2 ||
                        searchFormState.query.length === 0
                          ? 'valid'
                          : 'invalid'
                      }
                    >
                      <TextField.Label class="sr-only">
                        Search for names or keywords
                      </TextField.Label>
                      <div class="relative">
                        <TextField.Input
                          placeholder={'Search for names or keywords'}
                          class="w-full border-gray5 border-solid border-1px py-15 pl-40 md:pl-50 placeholder:text-gray2 rounded-4px text-16 font-camptonMedium leading-none "
                        />
                        <div class="absolute z-10 top-0 bottom-0 left-0 w-40 md:w-50 flex items-center justify-center">
                          <Icon icon="search" class=" text-blue size-18" />
                        </div>
                      </div>
                      <TextField.ErrorMessage class="md:absolute left-0 text-gray2 type-mobile-p3-medium pt-2">
                        Enter more than 3 characters
                      </TextField.ErrorMessage>
                    </TextField>

                    <Show when={searchFormState.query}>
                      <button
                        class="absolute top-0 bottom-0 right-0 w-50 flex items-center justify-center"
                        type="reset"
                        onClick={() => {
                          setSearchFormState('query', '')
                        }}
                      >
                        <Icon icon="close" class=" text-blue size-18" />
                      </button>
                    </Show>
                  </div>
                  <button type="submit" class="btn-small w-full md:max-w-240">
                    {props.searchButtonText}
                  </button>
                </form>
              </div>
            </div>

            {/* active filters and sort section */}
            <div class="md:flex justify-between items-center md:mb-80 mb-40">
              <Show
                when={searchFormState.departments.length > 0}
                fallback={<div />}
              >
                <div class="flex gap-10 md:gap-20 flex-wrap">
                  <For each={searchFormState.departments}>
                    {(dept) => (
                      <button
                        class="btn-outline-blue-small rounded-10px gap-10 leading-none"
                        onClick={() => {
                          clearSelectValue(dept.value)
                        }}
                      >
                        <span>{dept.label}</span>
                        <span>
                          <Icon icon="close" class="size-10" />
                        </span>
                      </button>
                    )}
                  </For>
                  <button
                    class="flex gap-10 items-center type-mobile-p2-medium text-blue link-hover"
                    onClick={() => {
                      handleClearFilters()
                    }}
                  >
                    <span>Clear All Filters</span>
                    <span>
                      <Icon icon="close" class="size-10" />
                    </span>
                  </button>
                </div>
              </Show>

              <div>
                <ZagSelect
                  label="Sort by"
                  placeholder="Sort By"
                  // setter={setDepartmentValues}
                  selectClass={cx(
                    'type-mobile-p2-semibold md:type-p2-semibold text-blue px-15 py-10 cursor-pointer flex gap-20 justify-between items-center max-md:pl-0'
                  )}
                  // values={departmentValues}
                  multiple={false}
                  onChange={(e) => {
                    const selected = e[0]
                    switch (selected.value) {
                      case 'alphabetical-asc':
                        setSearchFormState(
                          produce((state) => {
                            state.order = 'asc'
                            state.orderby = 'title'
                          })
                        )
                        break
                      case 'alphabetical-dec':
                        setSearchFormState(
                          produce((state) => {
                            state.order = 'desc'
                            state.orderby = 'title'
                          })
                        )
                        break
                    }
                  }}
                  defaultValue={['alphabetical-asc']}
                  options={
                    [
                      {
                        label: 'Alphabetical (Asc)',
                        value: 'alphabetical-asc',
                      },
                      {
                        label: 'Alphabetical (Desc)',
                        value: 'alphabetical-dec',
                      },
                    ] as const
                  }
                />
                {/* <KobalSelect
                  label={'Sort by'}
                  onChange={(e) => {
                    switch (e.value) {
                      case 'alphabetical-asc':
                        setSearchFormState(
                          produce((state) => {
                            state.order = 'asc'
                            state.orderby = 'title'
                          })
                        )
                        break
                      case 'alphabetical-dec':
                        setSearchFormState(
                          produce((state) => {
                            state.order = 'desc'
                            state.orderby = 'title'
                          })
                        )
                        break
                    }
                  }}
                  //   placeholder={'Sort by'}
                  defaultValue={{
                    label: 'Alphabetical (Asc)',
                    value: 'alphabetical-asc',
                  }}
                  options={
                    [
                      {
                        label: 'Alphabetical (Asc)',
                        value: 'alphabetical-asc',
                      },
                      {
                        label: 'Alphabetical (Desc)',
                        value: 'alphabetical-dec',
                      },
                    ] as const
                  }
                /> */}
              </div>
            </div>
          </div>
        )}
      </Match>
    </Switch>
  )
}

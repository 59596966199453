import DOMPurify from 'dompurify'
import { z } from 'zod'
import { decodeHtmlEntities } from '../utils'

const SizesSchema = z.object({
  medium: z.string().url(),
  'medium-width': z.number(),
  'medium-height': z.number(),
  large: z.string().url(),
  'large-width': z.number(),
  'large-height': z.number(),
})

const GalleryItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  filename: z.string(),
  filesize: z.number(),
  url: z.string().url(),
  link: z.string().url(),
  alt: z.string(),
  author: z.string(),
  description: z.string(),
  caption: z.string(),
  name: z.string(),
  status: z.string(),
  uploaded_to: z.number(),
  date: z.string(),
  modified: z.string(),
  menu_order: z.number(),
  mime_type: z.string(),
  type: z.string(),
  subtype: z.string(),
  icon: z.string().url(),
  width: z.number(),
  height: z.number(),
  sizes: SizesSchema,
})

const TaxonomyTermSchema = z.object({
  term_id: z.number(),
  name: z.string(),
  slug: z.string(),
  term_group: z.number(),
  term_taxonomy_id: z.number(),
  taxonomy: z.string(),
  description: z.string(),
  parent: z.number(),
  count: z.number(),
})

const AttachmentSchema = z.object({
  ID: z.number(),
  id: z.number(),
  title: z.string(),
  filename: z.string(),
  filesize: z.number(),
  url: z.string().url(),
  link: z.string().url(),
  alt: z.string(),
  author: z.string(),
  description: z.string(),
  caption: z.string(),
  name: z.string(),
  status: z.string(),
  uploaded_to: z.number(),
  date: z.string(),
  modified: z.string(),
  menu_order: z.number(),
  mime_type: z.string(),
  type: z.string(),
  subtype: z.string(),
  icon: z.string().url(),
})

const AttachmentWrapperSchema = z.object({
  attachment: AttachmentSchema,
})

const LinkSchema = z.object({
  title: z.string(),
  url: z.string().url(),
  target: z.string().nullish(),
})
export type Link = z.infer<typeof LinkSchema>

const LinkWrapperSchema = z.object({
  type: z.union([z.literal('link'), z.literal('video')]),
  link: LinkSchema.or(z.string()),
})

const CaseStudyAcfSchema = z.object({
  funding_source: z.string().nullish(),
  date_added: z.string().nullish(),
  location: z.string().nullish(),
  responsible_party: z.string().nullish(),
  gallery: z.array(GalleryItemSchema).or(z.literal(false)),
  location_population: z
    .string()
    .nullish()
    .transform((value) => {
      if (value) {
        return Number(value).toLocaleString('en-US')
      }
      return null
    }),
  league_program_area: z.array(TaxonomyTermSchema).or(z.literal(false)),
  focus_area: z.array(TaxonomyTermSchema).or(z.literal(false)),
  region: TaxonomyTermSchema.or(z.literal(false)),
  attachments: z.array(AttachmentWrapperSchema).or(z.literal(false)),
  links: z
    .array(LinkWrapperSchema)
    .transform((links) => {
      const filterEmpty = links.filter(
        (
          link
        ): link is {
          type: 'link' | 'video'
          link: {
            title: string
            url: string
            target?: string | null | undefined
          }
        } => typeof link.link !== 'string'
      )

      return filterEmpty
    })
    .or(z.literal(false)),
})

export const SingleCaseStudySchema = z.object({
  id: z.number(),
  link: z.string().url(),
  title: z.object({
    rendered: z.string().transform((data) => {
      return decodeHtmlEntities(data)
    }),
  }),
  content: z.object({
    rendered: z.string(),
  }),
  excerpt: z.object({
    rendered: z.string().transform((data) => {
      return DOMPurify.sanitize(data, {
        ALLOWED_TAGS: [],
      })
    }),
  }),
  guid: z.object({
    rendered: z.string(),
  }),
  acf: CaseStudyAcfSchema,
  slug: z.string(),
  featured_image: z
    .object({
      id: z.number(),
      url: z.string(),
      width: z.number(),
      height: z.number(),
      alt: z.string(),
      caption: z.string(),
    })
    .nullish(),
})

export const CaseStudyListSchema = z.array(SingleCaseStudySchema)
export type CaseStudyList = z.infer<typeof CaseStudyListSchema>

export type SingleCaseStudy = z.infer<typeof SingleCaseStudySchema>

// You can use this schema to validate data like this:
// const validatedData = CaseStudySchema.parse(jsonData);

import DOMPurify from 'dompurify'
import { z } from 'zod'
import { decodeHtmlEntities } from './utils'

const TaxonomyTermSchema = z.object({
  term_id: z.number(),
  count: z.number(),
  description: z.string(),
  // link: z.string(),
  name: z.string(),
  slug: z.string(),
  taxonomy: z.string(),
  parent: z.number(),
  // meta: z.array(z.unknown()),
})

export const FiltersResponseSchema = z.object({
  league_program_areas: z.array(TaxonomyTermSchema),
  focus_areas: z.array(TaxonomyTermSchema),
  regions: z.array(TaxonomyTermSchema),
  location_populations: z.record(z.string(), z.string()),
  enabled_filters: z.object({
    league_program_areas: z.array(z.string()),
    focus_areas: z.array(z.string()),
    regions: z.array(z.string()),
    location_populations: z.array(z.string()),
  }),
})

const CaseStudyFiterGroupSchema = z.array(
  z.object({
    term: z.string(),
    slug: z.string(),
    count: z.number(),
    term_id: z.string(),
  })
)

export type CaseStudyFiterGroup = z.infer<typeof CaseStudyFiterGroupSchema>

export const CaseStudyFiltersResponseSchema = z.object({
  'league-program-area': CaseStudyFiterGroupSchema,
  'focus-area': CaseStudyFiterGroupSchema,
  region: CaseStudyFiterGroupSchema,
  location_population: CaseStudyFiterGroupSchema,
})

export type CaseStudyFiltersResponse = z.infer<
  typeof CaseStudyFiltersResponseSchema
>

export type TaxonomyTerm = z.infer<typeof TaxonomyTermSchema>
export type FiltersResponse = z.infer<typeof FiltersResponseSchema>

export const CaseStudySchema = z.object({
  id: z.number(),
  url: z.string(),
  title: z.string().transform((data) => {
    return decodeHtmlEntities(data)
  }),
  content: z.string(),
  excerpt: z.string().transform((data) => {
    return DOMPurify.sanitize(data, {
      ALLOWED_TAGS: ['strong'],
    })
  }),
  league_program_area: z.array(z.string()),
  focus_area: z.array(z.string()),
  region: z.array(z.string()),
  location_population: z.string().nullish(),
  handle: z.string().transform((data) => {
    return data.endsWith('/') ? data : `${data}/`
  }),
  featured_image: z
    .object({
      id: z.number(),
      url: z.string(),
      width: z.number(),
      height: z.number(),
      alt: z.string(),
      caption: z.string(),
    })
    .nullish(),
})

export const CaseStudiesResponseSchema = z.object({
  items: z.array(CaseStudySchema),
  totalPages: z.number(),
})

export type CaseStudy = z.infer<typeof CaseStudySchema>
export type CaseStudiesResponse = z.infer<typeof CaseStudiesResponseSchema>

export const AppSettingsSchema = z.object({
  app_slug: z.string().transform((data) => {
    if (
      window.location.host === 'localhost:3000' ||
      window.location.host.includes('netlify.app')
    ) {
      return '/'
    }
    return data
  }),
  region_map_page: z
    .object({
      title: z.string(),
      url: z.string(),
      target: z.string(),
    })
    .transform((val, ctx) => {
      if (typeof val === 'string') {
        return false
      }
      return val
    }),
  app_page: z.number(),
})
export type AppSettings = z.infer<typeof AppSettingsSchema>

import { createFocusTrap } from 'focus-trap'
import queryString from 'query-string'
export const qs = queryString
export type HtmlElementHelper = {
  class?: string
  id?: string
  classList?:
    | {
        [k: string]: boolean | undefined
      }
    | undefined
}

export type Option = {
  label: string
  value: string
}

export { createFocusTrap }

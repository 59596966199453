import DOMPurify from 'dompurify'
import { createMemo, type Component } from 'solid-js'

type SafeHtmlProps = {
  input: string
  class?: string
  tag?: keyof HTMLElementTagNameMap
}

export const SafeHtml: Component<SafeHtmlProps> = (props) => {
  const htmlElement = createMemo(() => {
    const e = document.createElement(props.tag || 'div')
    e.innerHTML = DOMPurify.sanitize(props.input)
    if (props.class) {
      e.classList.add(...props.class.trim().split(/\s+/))
    }
    return e
  })

  return <>{htmlElement()}</>
}

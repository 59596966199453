import { createContextProvider } from '@solid-primitives/context'
import { createSignal } from 'solid-js'
import { createStore } from 'solid-js/store'
import type { Option } from '../../helpers'
import type { SingleStaff } from '../../types/StaffTypes'
import type { WpRestOrdering } from '../../types/WordPress'

const [StaffAppContextProvider, useStaff] = createContextProvider(
  (props: { shadowRoot?: ShadowRoot }) => {
    const [searchFormState, setSearchFormState] = createStore<
      {
        query: string
        departments: Option[]
      } & WpRestOrdering
    >({
      query: '',
      departments: [],
      orderby: 'title',
      order: 'asc',
    })

    const [openStaffModal, setOpenStaffModal] = createSignal<SingleStaff>()

    return {
      searchFormState,
      setSearchFormState,
      openStaffModal,
      setOpenStaffModal,
      shadowRoot: () => props.shadowRoot,
    }
  }
)

const useStaffContext = () => useStaff()!

export { StaffAppContextProvider, useStaffContext }

import { isDev } from '@solid-primitives/utils'
import { Show, type ParentComponent } from 'solid-js'

type DevOnlyProps = {
  class?: string
}
export const DevOnly: ParentComponent<DevOnlyProps> = (props) => {
  return (
    <Show when={isDev}>
      <div class={props.class}>{props.children}</div>
    </Show>
  )
}

import { FIGMA_TYPOGRAPHY } from './figma-typography'
import { em, rem, round } from './tailwind-helpers'
import { tailwindTheme } from './tailwind-theme'

export const prose = () => ({
  wysiwygContent: {
    css: {
      a: {
        color: tailwindTheme.colors.blue,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none !important',
        },
      },
    },
  },
  DEFAULT: {
    css: {
      maxWidth: 'none',
      'p,ul,ol,li': {
        fontSize: tailwindTheme.fontSize['16'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
      },
      img: {
        '&.size-full': {
          width: 'auto',
          height: 'auto',
        },
      },
      h1: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h2: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },

      h3: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h4: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h5: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: tailwindTheme.colors.blue,
      },
      h6: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: tailwindTheme.colors.blue,
      },
      a: {
        transitionDuration: '150ms',
        '&:hover': {
          textDecoration: 'none !important',
        },
      },
      span: {
        fontSize: 'inherit',
        lineHeight: 'inherit',
      },
      table: {
        // border: `${tailwindTheme.colors.grays.borderOnWhite} 1px solid`,
        border: 'none',
        width: '100%',
        a: {
          color: tailwindTheme.colors.blue,

          ...FIGMA_TYPOGRAPHY['.type-p3-semibold'],
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none !important',
          },
        },
      },
      // "td,tbody tr th": {
      //   border: `${tailwindTheme.colors.gray5} 1px solid`,
      //   padding: `${rem(10)} ${rem(20)}`,
      //   // "@media (min-width: 1024px)": {
      //   //   padding: `${rem(10)} ${rem(20)}`,
      //   // },
      // },
      th: {
        padding: `${rem(10)} ${rem(20)}`,
        ...FIGMA_TYPOGRAPHY['.type-p3-semibold'],
      },
      td: {
        color: tailwindTheme.colors.black,
        verticalAlign: 'top',
        border: `${tailwindTheme.colors.gray5} 1px solid`,
        padding: `${rem(10)} ${rem(20)}`,
        ...FIGMA_TYPOGRAPHY['.type-p3-medium'],
        '&:first-child': {
          ...FIGMA_TYPOGRAPHY['.type-p3-semibold'],
        },
      },

      tbody: {
        border: `${tailwindTheme.colors['gray5']} 1px solid`,
      },
      thead: {
        backgroundColor: tailwindTheme.colors.blue,
        border: `${tailwindTheme.colors.blue} 1px solid`,
        th: {
          border: `${tailwindTheme.colors.gray5} 1px solid`,
          borderTop: 'none',
          borderBottom: 'none',
          color: tailwindTheme.colors.white,
          padding: `${rem(10)} ${rem(20)}`,
          ...FIGMA_TYPOGRAPHY['.type-p3-semibold'],
          // "@media (min-width: 1024px)": {
          //   ...FIGMA_TYPOGRAPHY[".type-p-2-regular"],
          //   padding: `${rem(10)} ${rem(20)}`,
          // },
        },
      },
      tfoot: {
        border: `${tailwindTheme.colors['gray5']} 1px solid`,
        // backgroundColor: tailwindTheme.colors.main.red,
        // border: `${tailwindTheme.colors["iron"]} 1px solid`,
        // borderTop: `${tailwindTheme.colors["iron"]} 1px solid`,
      },
      // input: {
      //   '&[type="text"]': {
      //     padding: `${rem(16)} ${rem(15)} ${rem(14)}`,
      //     "@media (min-width: 768px)": {
      //       padding: `${rem(17)} ${rem(38)} ${rem(15)}`,
      //     },
      //   },
      // },
      tr: {},
      '--tw-prose-bullets': tailwindTheme.colors.black,
      '--tw-prose-body': tailwindTheme.colors.black,
      '--tw-prose-links': tailwindTheme.colors.blue,
      '--tw-prose-headings': tailwindTheme.colors.blue,
      // '--tw-prose-lead': theme('colors.pink[700]'),
      // '--tw-prose-links': theme('colors.pink[900]'),
      // '--tw-prose-bold': theme('colors.pink[900]'),
      // '--tw-prose-counters': theme('colors.pink[600]'),
      // '--tw-prose-bullets': theme('colors.pink[400]'),
      '--tw-prose-hr': tailwindTheme.colors.gray5,
      '--tw-prose-quotes': tailwindTheme.colors.lightBlue,
      // '--tw-prose-quote-borders': theme('colors.pink[300]'),
      // '--tw-prose-captions': theme('colors.pink[700]'),
      // '--tw-prose-code': theme('colors.pink[900]'),
      // '--tw-prose-pre-code': theme('colors.pink[100]'),
      // '--tw-prose-pre-bg': theme('colors.pink[900]'),
      // '--tw-prose-th-borders': theme('colors.pink[300]'),
      // '--tw-prose-td-borders': theme('colors.pink[200]'),
      // '--tw-prose-invert-body': theme('colors.pink[200]'),
      // '--tw-prose-invert-headings': theme('colors.white'),
      // '--tw-prose-invert-lead': theme('colors.pink[300]'),
      // '--tw-prose-invert-links': theme('colors.white'),
      // '--tw-prose-invert-bold': theme('colors.white'),
      // '--tw-prose-invert-counters': theme('colors.pink[400]'),
      // '--tw-prose-invert-bullets': theme('colors.pink[600]'),
      // '--tw-prose-invert-hr': theme('colors.pink[700]'),
      // '--tw-prose-invert-quotes': theme('colors.pink[100]'),
      // '--tw-prose-invert-quote-borders': theme('colors.pink[700]'),
      // '--tw-prose-invert-captions': theme('colors.pink[400]'),
      // '--tw-prose-invert-code': theme('colors.white'),
      // '--tw-prose-invert-pre-code': theme('colors.pink[300]'),
      // '--tw-prose-invert-pre-bg': 'rgb(0 0 0 / 50%)',
      // '--tw-prose-invert-th-borders': theme('colors.pink[600]'),
      // '--tw-prose-invert-td-borders': theme('colors.pink[700]'),
    },
  },
  textBlockMobile: {
    css: {
      p: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p1-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
      },
      h5: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-h4-semibold'],
      },
    },
  },
  textBlockDesktop: {
    css: {
      p: {
        ...FIGMA_TYPOGRAPHY['.type-p2-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
      },
      h5: {
        ...FIGMA_TYPOGRAPHY['.type-h5-bold'],
      },
    },
  },
  mobile: {
    css: {
      ...FIGMA_TYPOGRAPHY['.type-mobile-p2-medium'],
      p: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p2-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
        marginBottom: '20px',
      },

      h1: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-h4-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h2: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-h4-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },

      h3: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h4: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h5: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h6: {
        ...FIGMA_TYPOGRAPHY['.type-mobile-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },

      'ul > li,ol > li': {
        paddingLeft: em(0, 16),
        ...FIGMA_TYPOGRAPHY['.type-mobile-p2-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
      },
      'h1,h2,h3,h4,h5,h6': {
        marginBottom: '0.5em',
      },
      hr: {
        marginTop: em(48, 16),
        marginBottom: em(48, 16),
        backgroundColor: tailwindTheme.colors.gray5,
      },
    },
  },
  md: {
    css: {
      ...FIGMA_TYPOGRAPHY['.type-p3-medium'],
      p: {
        ...FIGMA_TYPOGRAPHY['.type-p3-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
        marginBottom: '20px',
      },
      'ul > li,ol > li': {
        ...FIGMA_TYPOGRAPHY['.type-p3-medium'],
        fontFamily: tailwindTheme.fontFamily.camptonBook,
      },
      h1: {
        ...FIGMA_TYPOGRAPHY['.type-h5-bold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h2: {
        ...FIGMA_TYPOGRAPHY['.type-h5-bold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },

      h3: {
        ...FIGMA_TYPOGRAPHY['.type-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h4: {
        ...FIGMA_TYPOGRAPHY['.type-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h5: {
        ...FIGMA_TYPOGRAPHY['.type-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      h6: {
        ...FIGMA_TYPOGRAPHY['.type-p1-semibold'],
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
    },
  },
  pageContent: {
    css: [
      {
        '--tw-prose-bullets': tailwindTheme.colors.black,
        '--tw-prose-counters': tailwindTheme.colors.black,
        color: tailwindTheme.colors.black,
        ...FIGMA_TYPOGRAPHY['.type-p1-medium'],

        p: {
          ...FIGMA_TYPOGRAPHY['.type-p1-medium'],
          fontFamily: tailwindTheme.fontFamily.camptonBook,
        },
        'div>iframe': {
          display: 'inline-block',
        },
        iframe: {
          display: 'inline-block',
          maxWidth: '100%',
        },
        h1: {
          ...FIGMA_TYPOGRAPHY['.type-h2-bold'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        h2: {
          ...FIGMA_TYPOGRAPHY['.type-h3-bold'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        h3: {
          ...FIGMA_TYPOGRAPHY['.type-h4-semibold'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        h4: {
          ...FIGMA_TYPOGRAPHY['.type-h5-bold'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        h5: {
          ...FIGMA_TYPOGRAPHY['.type-h6-semibold'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        h6: {
          ...FIGMA_TYPOGRAPHY['.type-h7-semibold-all-caps'],
          marginTop: '1.5em',
          marginBottom: '0.5em',
        },
        ol: {
          marginTop: em(0, 16),
          marginBottom: em(50, 16),
          paddingLeft: em(26, 16),
        },
        ul: {
          marginTop: em(0, 16),
          marginBottom: em(50, 16),
          paddingLeft: em(26, 16),
        },
        li: {
          marginTop: em(8, 16),
          marginBottom: em(8, 16),
          '&:first-child': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
        'ol > li': {
          paddingLeft: em(6, 16),
          ...FIGMA_TYPOGRAPHY['.type-p1-medium'],
        },
        'ul > li': {
          paddingLeft: em(6, 16),
          ...FIGMA_TYPOGRAPHY['.type-p1-medium'],
        },
        '> ul > li p': {
          marginTop: em(12, 16),
          marginBottom: em(12, 16),
        },
        '> ul > li > *:first-child': {
          marginTop: em(20, 16),
        },
        '> ul > li > *:last-child': {
          marginBottom: em(20, 16),
        },
        '> ol > li p': {
          marginTop: em(12, 16),
          marginBottom: em(12, 16),
        },
        '> ol > li > *:first-child': {
          marginTop: em(20, 16),
        },
        '> ol > li > *:last-child': {
          marginBottom: em(20, 16),
        },
        'ul ul, ul ol, ol ul, ol ol': {
          marginTop: em(12, 16),
          marginBottom: em(12, 16),
        },
        dl: {
          marginTop: em(20, 16),
          marginBottom: em(20, 16),
        },
        dt: {
          marginTop: em(20, 16),
        },
        dd: {
          marginTop: em(8, 16),
          paddingLeft: em(26, 16),
        },
        hr: {
          marginTop: em(48, 16),
          marginBottom: em(48, 16),
        },

        table: {
          border: `${tailwindTheme.colors['gray5']} 1px solid !important`,
          td: {
            border: `${tailwindTheme.colors['gray5']} 1px solid !important`,
            fontWeight: 'normal !important',
          },
        },

        kbd: {
          fontSize: em(14, 16),
          borderRadius: rem(5),
          paddingTop: em(3, 16),
          paddingRight: em(6, 16),
          paddingBottom: em(3, 16),
          paddingLeft: em(6, 16),
        },
        code: {
          fontSize: em(14, 16),
        },

        pre: {
          fontSize: em(14, 16),
          lineHeight: round(24 / 14),
          marginTop: em(24, 14),
          marginBottom: em(24, 14),
          borderRadius: rem(6),
          paddingTop: em(12, 14),
          paddingRight: em(16, 14),
          paddingBottom: em(12, 14),
          paddingLeft: em(16, 14),
        },

        blockquote: {
          marginTop: em(32, 20),
          marginBottom: em(32, 20),
          paddingLeft: em(20, 20),
        },

        img: {
          marginTop: em(32, 16),
          marginBottom: em(32, 16),
        },
        picture: {
          marginTop: em(32, 16),
          marginBottom: em(32, 16),
        },
        'picture > img': {
          marginTop: '0',
          marginBottom: '0',
        },
        video: {
          marginTop: em(32, 16),
          marginBottom: em(32, 16),
        },
      },
    ],
  },
})

import ky from 'ky'
import { z } from 'zod'

export const wpQueryApiBase = `${window.location.origin}/wp-json/wp/v2`
export const wpQueryCustomApiBase = `${window.location.origin}/wp-json/wp/v2/custom`

export const getSubsiteCustomApiRoute = (subsiteId: number, path: string) => {
  return `${window.location.origin}/wp-json/wp/site/${subsiteId}/v2/custom/${path}`
}

type Menus = 'main-nav-menu' | 'main-utility-menu'
export async function getMenu(menu: Menus) {
  const isSyncedMainNavMenu = window.shared_child_container_settings?.sync_main_nav_menu

  if (isSyncedMainNavMenu) {
    const mainSiteMenuResponse = schemaGetMenu.parse(
      await ky.get(getSubsiteCustomApiRoute(1, `menus/${menu}`)).json()
    )
    return mainSiteMenuResponse
  } else {
    const customMenuResponse = schemaGetMenu.parse(
      await ky.get(getSubsiteCustomApiRoute(window.currentBlogId, `menus/${menu}`)).json()
    )
    return customMenuResponse
  }
}

const schemaMenu = z.object({
  ID: z.number(),
  post_author: z.string(),
  post_date: z.string(),
  post_date_gmt: z.string(),
  post_content: z.string(),
  post_title: z.string(),
  post_excerpt: z.string(),
  post_status: z.string(),
  comment_status: z.string(),
  ping_status: z.string(),
  post_password: z.string(),
  post_name: z.string(),
  to_ping: z.string(),
  pinged: z.string(),
  post_modified: z.string(),
  post_modified_gmt: z.string(),
  post_content_filtered: z.string(),
  post_parent: z.number(),
  guid: z.string(),
  menu_order: z.number(),
  post_type: z.string(),
  post_mime_type: z.string(),
  comment_count: z.string(),
  filter: z.string(),
  db_id: z.number(),
  menu_item_parent: z.string(),
  object_id: z.string(),
  object: z.string(),
  type: z.string(),
  type_label: z.string(),
  url: z.string(),
  title: z.string(),
  target: z.string(),
  attr_title: z.string(),
  description: z.string(),
  classes: z.array(z.string()),
  xfn: z.string(),
})
const schemaMenuWithSubmenu = schemaMenu.extend({
  submenu: z.array(schemaMenu.extend({ submenu: z.array(schemaMenu).nullish() })).nullish(),
})

const schemaGetMenu = z.array(schemaMenuWithSubmenu)

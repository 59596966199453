import { z } from 'zod'

const BreadCrumbListItemSchema = z.object({
  '@type': z.literal('BreadcrumbList'),
  '@id': z.string(),
  itemListElement: z.array(
    z.object({
      '@type': z.literal('ListItem'),
      position: z.number(),
      name: z.string(),
      item: z.string().url().optional(),
    })
  ),
})

export type BreadCrumbListItem = z.infer<typeof BreadCrumbListItemSchema>
export const yoastHeadJsonSchema = z.object({
  yoast_head_json: z
    .object({
      title: z.string(),
      schema: z.object({
        '@graph': z.array(
          z.discriminatedUnion('@type', [
            z.object({
              '@type': z.literal('WebPage'),
              '@id': z.string().url(),
            }),
            BreadCrumbListItemSchema,
            z.object({
              '@type': z.literal('WebSite'),
              '@id': z.string(),
            }),
          ])
        ),
      }),
    })
    .nullish(),
})

export type YoastHeadJson = z.infer<typeof yoastHeadJsonSchema>

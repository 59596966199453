import { createForm } from '@felte/solid'
import { Component, createEffect, createResource, createSignal, For, on, Show } from 'solid-js'

import gsap, { Power4 } from 'gsap'
import { getMenu } from '../../api/customWpQuery'
import { breakpoints, cx } from '../../utils'
import { decodeHtml } from '../../utils/decodeHtml'
import { ChevronLeft, ChevronRight, IconClose, IconHamburger, IconSearch } from '../LegacyIcons'

async function fetchMenu() {
  const data = await getMenu('main-nav-menu')
  return data
}

type MobileHeaderProps = {
  logo: string
}
export const MobileHeader: Component<MobileHeaderProps> = (props) => {
  const [openMenu, setOpenMenu] = createSignal<boolean>()
  const [openSearch, setOpenSearch] = createSignal<boolean>()
  let mobileMenu: HTMLDivElement
  let searchForm: HTMLDivElement
  let searchInput: HTMLInputElement
  const { form } = createForm({
    // ...
    onSubmit: (values) => {
      console.log(values)
      const searchUrl = `${window.location.origin}/?s=${encodeURIComponent(`${values.s}`)}`
      window.location.href = searchUrl
    },
    // ...
  })
  createEffect(
    on(
      openMenu,
      (menu) => {
        if (menu) {
          gsap.to(mobileMenu, {
            height: 'auto',
            ease: Power4.easeInOut,
          })
          // document.body.classList.add('overflow-hidden')
          document.documentElement.style.overflow = 'hidden'
        } else {
          gsap.to(mobileMenu, {
            height: '0',
            ease: Power4.easeInOut,
          })
          // document.body.classList.remove('overflow-hidden')
          document.documentElement.style.removeProperty('overflow')
        }
      },
      { defer: false }
    )
  )

  createEffect(() => {
    if (breakpoints.md) {
      setOpenMenu(false)
    }
  })
  return (
    <>
      <header class="h-full">
        {/* search form */}
        <div
          ref={(e) => (searchForm = e)}
          class={`absolute inset-0 grid grid-cols-8 items-center bg-blue px-mobileGutter transition-all duration-300 ${
            openSearch() ? 'z-10 opacity-100' : '-z-10 opacity-0'
          }`}
        >
          <form
            ref={form}
            class="relative col-span-7"
            // onSubmit={(e) => {
            //   // e.preventDefault()

            //   console.log()
            //   window.location.assign(searchUrl)
            // }}
          >
            <label>
              <span class="sr-only">search bar</span>
              <input
                type="text"
                name="s"
                ref={(e) => (searchInput = e)}
                class="h-40 w-full rounded-20px focus:border-blue"
              />
            </label>
            <button
              onClick={(e) => {
                // e.preventDefault()
                // console.log('search!')
              }}
              class="absolute bottom-0 right-0 top-0 flex items-center px-20"
            >
              <span class="sr-only">submit search</span>
              <IconSearch class="size-20 text-blue" />
            </button>
          </form>

          <div class="relative col-span-1 flex justify-end">
            <button
              onClick={() => {
                setOpenSearch(false)
              }}
              class="size-32 p-4"
            >
              <span class="sr-only">close search</span>
              <IconClose class="h-full w-full text-white" />
            </button>
          </div>
        </div>
        <div class="grid h-full grid-cols-8 items-center justify-between bg-blue px-mobileGutter py-16">
          <div class="col-span-6">
            <a href="/" class="block h-64 w-[8.5rem]">
              <span class="sr-only">Go to MML.org homepage</span>
              <img src={props.logo} alt="MML Logo" class="max-h-full max-w-full text-white" />
            </a>
          </div>
          <div class="col-span-2 flex">
            <div class="flex w-1/2 justify-end">
              <button
                onClick={() => {
                  setOpenSearch(true)
                  searchInput.focus()
                  console.log(searchInput)
                }}
                class="relative size-32 p-8"
              >
                <span class="sr-only">open search bar</span>
                <IconSearch class="h-full w-full text-white" />
              </button>
            </div>
            <div class="flex w-1/2 justify-end">
              <button
                id="primary-menu-toggle"
                class={`${openMenu() ? 'is-open' : ''} group relative block size-32 bg-blue`}
                aria-controls="primary-menu"
                aria-expanded="false"
                onClick={() => {
                  setOpenMenu((prev) => !prev)
                }}
              >
                <span class="sr-only">{openMenu() ? 'close main menu' : 'open main menu'}</span>
                <span class="absolute inset-0 block h-full w-full p-4 transition-all duration-300 group-[.is-open]:opacity-0">
                  <IconHamburger class="h-full w-full text-white" />
                </span>
                <span class="absolute inset-0 h-full w-full p-4 opacity-0 transition-all duration-300 group-[.is-open]:opacity-100">
                  <IconClose class="h-full w-full text-white" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          ref={(e) => (mobileMenu = e)}
          id="ky-mobile-header-dropdown-menu-solid"
          class="fixed bottom-0 left-0 right-0 top-mobileMenuHeight z-50 h-0 overflow-hidden bg-legacy-paleBlue"
        >
          <MobileMenu />
        </div>
      </header>
    </>
  )
}

const MobileMenu: Component = () => {
  type submenu = {
    text: string
    index: number
    // height: number
  }
  const [subMenu, setSubMenu] = createSignal<submenu>()
  const [menu] = createResource(fetchMenu)
  const subMenu2TextStyle = cx(
    'link-hover text-21 leading-30 border-b-1px border-legacy-paleBlue mb-12 block  pb-12 font-bold'
  )
  const subMenu3TextStyle = cx('text-17 leading-23 link-hover block py-8')
  function handleOpenSubmenu() {}

  return (
    <div class="h-full overflow-y-auto overscroll-contain text-blue">
      {/* mobile header bar */}
      <div
        onClick={() => {
          if (!subMenu()) return
          setSubMenu(undefined)
        }}
        class={[
          `${subMenu() ? 'group cursor-pointer bg-legacy-paleBlue' : 'bg-white'}`,
          'absolute left-0 right-0 top-0 z-30 h-64',
        ].join(' ')}
      >
        <div class="flex flex-nowrap items-center justify-center px-mobileGutter py-16 transition-all duration-300 group-hover:opacity-75">
          <Show when={subMenu()}>
            <button
              class={`absolute left-mobileGutter mr-16 flex size-32 flex-initial items-center justify-center rounded-full bg-white p-8 text-32 text-black`}
            >
              <span class="sr-only">go back to main menu</span>
              <ChevronLeft class="h-full max-h-full w-full max-w-full text-inherit" />
            </button>
          </Show>
          <span
            class="mt-8 text-18 font-medium"
            innerHTML={subMenu()?.text ? subMenu()?.text : 'Menu'}
          />
        </div>
      </div>
      <div class="mt-64">
        <nav class="divide-y-1px divide-adaAltBlue px-mobileGutter">
          <For each={menu()}>
            {(menuItem1, i) => {
              let $subMenu: HTMLDivElement
              return (
                <div>
                  <div class="flex items-center justify-between">
                    <a
                      class={`${
                        window.location.href == menuItem1.url ? 'underline' : ''
                      } flex-grow py-20 text-18 font-medium leading-28 link-hover`}
                      href={menuItem1.url}
                      innerHTML={menuItem1.title}
                      target={menuItem1.target}
                    />
                    <Show when={menuItem1.submenu}>
                      <div class="pl-8">
                        <button
                          onClick={() => {
                            setSubMenu({ text: menuItem1.title, index: i() })
                            console.log($subMenu.clientHeight)
                            console.log($subMenu)
                          }}
                          class={`flex size-32 flex-initial items-center justify-center rounded-full bg-white p-8 text-32 text-black transition-all duration-300 hover:bg-blue hover:text-white`}
                        >
                          <span class="sr-only">
                            open {decodeHtml(menuItem1.title)} sub menu items
                          </span>
                          <ChevronRight class="h-full max-h-full w-full max-w-full text-inherit" />
                        </button>
                      </div>
                    </Show>
                  </div>

                  <div
                    ref={(e) => ($subMenu = e)}
                    class={`no-scrollbar absolute bottom-0 left-0 right-0 top-64 z-20 h-full overflow-scroll overscroll-contain bg-white px-mobileGutter py-mobileGutter pb-96 transition-transform duration-300 ${
                      subMenu()?.index == i() ? 'translate-x-0' : 'translate-x-full'
                    }`}
                  >
                    <For each={menuItem1.submenu}>
                      {(menuItem2) => (
                        <div class="">
                          <a
                            class={[
                              `${window.location.href == menuItem2.url ? 'underline' : ''} `,
                              `${menuItem2.submenu ? subMenu2TextStyle : subMenu3TextStyle}`,
                            ].join(' ')}
                            href={menuItem2.url}
                            innerHTML={menuItem2.title}
                            target={menuItem2.target}
                          />
                          <Show when={menuItem2.submenu}>
                            <div class="pb-24">
                              <For each={menuItem2.submenu}>
                                {(menuItem3) => (
                                  <a
                                    class={[
                                      `${window.location.href == menuItem3.url ? 'underline' : ''}`,
                                      subMenu3TextStyle,
                                    ].join(' ')}
                                    href={menuItem3.url}
                                    innerHTML={menuItem3.title}
                                    target={menuItem3.target}
                                  />
                                )}
                              </For>
                            </div>
                          </Show>
                        </div>
                      )}
                    </For>
                  </div>
                </div>
              )
            }}
          </For>
        </nav>
      </div>
    </div>
  )
}

import { z } from 'zod'
import { yoastHeadJsonSchema } from './yoast_head_json'

export const WpPageSchema = z
  .object({
    id: z.number(),
    link: z.string(),
    // date: z.string(),
    // date_gmt: z.string(),
    // guid: z.string(),
    // modified: z.string(),
    // modified_gmt: z.string(),
    // slug: z.string(),
    // status: z.string(),
    // type: z.string(),
    // link: z.string(),
    // title: z.object({
    //   rendered: z.string(),
    // }),
    // content: z.object({
    //   rendered: z.string(),
    // }),
    // excerpt: z.object({
    //   rendered: z.string(),
    // }),
  })
  .merge(yoastHeadJsonSchema)

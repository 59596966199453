import { createForm } from '@felte/solid'
import { createWindowSize } from '@solid-primitives/resize-observer'
import gsap, { Power4 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import {
  Component,
  createEffect,
  createResource,
  createSignal,
  For,
  Match,
  on,
  Show,
  Suspense,
  Switch,
} from 'solid-js'

import { getMenu } from '../../api/customWpQuery'
import { cx } from '../../utils'
import { IconClose, IconSearch } from '../LegacyIcons'

gsap.registerPlugin(ScrollTrigger)

async function fetchMainNavMenu() {
  const data = await getMenu('main-nav-menu')
  return data
}
async function fetchMainUtilityNav() {
  const data = await getMenu('main-utility-menu')
  return data
}

type DesktopHeaderProps = {
  logo: string
}
export const DesktopHeader: Component<DesktopHeaderProps> = (props) => {
  const [menu] = createResource(fetchMainNavMenu)
  const [utilityNavMounted, setUtilityNavMounted] = createSignal<boolean>(false)
  // let logoLink: HTMLAnchorElement
  let mainNavHeader: HTMLDivElement
  let entireHeader: HTMLElement
  let utilityNav: HTMLDivElement
  const size = createWindowSize()

  createEffect(
    on(
      utilityNavMounted,
      () => {
        const animateTl = gsap.timeline({ paused: true })
        animateTl.to(utilityNav, { marginTop: `-${utilityNav.offsetHeight}px` })
        // .to(
        //   mainNavHeader,
        //   {
        //     paddingTop: '1.5rem',
        //     paddingBottom: '1.5rem',
        //   },
        //   0
        // )

        ScrollTrigger.create({
          // markers: true,
          // trigger: mainNavHeader,
          start: `+=${entireHeader.offsetHeight / 2} top`,
          end: `+=${document.body.offsetHeight}`,
          onLeaveBack: () => {
            animateTl.reverse()
            // mainNavHeader.classList
          },

          onEnter: () => {
            animateTl.play()
          },
          toggleClass: { targets: mainNavHeader, className: 'shadow-lg' },
        })
      },
      { defer: true }
    )
  )

  const [menuReady, setMenuReady] = createSignal(false)
  createEffect(() => {
    if (!menu() || !utilityNavMounted()) return
    setMenuReady(true)
  })

  const subMenuLink1TextStyles = cx(
    'text-21 leading-30 text-blue font-bold border-b-2px border-legacy-paleBlue mb-8 pb-8 block link-hover'
  )
  const subMenuLink2TextStyles = cx(
    'text-17 text-blue leading-24 py-8 block link-hover whitespace-nowrap'
  )
  return (
    <>
      <header ref={(e) => (entireHeader = e)}>
        <Suspense fallback={<div />}>
          <UtilityNav ref={(e) => (utilityNav = e)} callback={setUtilityNavMounted} />
          <div
            ref={(e) => (mainNavHeader = e)}
            class={cx([
              'px-mobileGutter py-20 text-white 2xl:px-desktopOutsideGutter',
              menuReady() ? 'bg-blue' : 'bg-blue',
            ])}
          >
            <div class="xl:gap-x-desktopColumnGutter mx-auto grid max-w-mainNavContainer grid-cols-12 items-center gap-x-24">
              <div class="col-span-2">
                <a
                  // ref={(e) => (logoLink = e)}
                  href="/"
                  class="block h-auto w-144 max-w-full text-white transition-none"
                >
                  <span class="sr-only">{`MML Logo - link to home page`}</span>
                  <img
                    src={props.logo}
                    alt="MML Logo"
                    class="h-auto max-h-full w-full max-w-full"
                  />
                </a>
              </div>
              <nav class="col-span-10 mr-[-2.1875rem] flex items-center justify-end">
                <For each={menu()}>
                  {(menuLink) => {
                    let topLevelNavContainer: HTMLDivElement
                    const [dropDownTl, setdropDownTl] = createSignal<gsap.core.Timeline>()
                    let subMenu: HTMLElement

                    createEffect(() => {
                      const topLevelNavPos = topLevelNavContainer.getBoundingClientRect()
                      if (
                        subMenu.getBoundingClientRect().width + topLevelNavPos.left + 2.1875 * 16 >
                        size.width
                      ) {
                        topLevelNavContainer.classList.remove('is-left')
                        topLevelNavContainer.classList.add('is-right')
                      } else {
                        topLevelNavContainer.classList.remove('is-right')
                        topLevelNavContainer.classList.add('is-left')
                      }

                      setdropDownTl(
                        gsap
                          .timeline({ paused: true })
                          .to(subMenu, {
                            height: 'auto',
                            duration: 0,
                            zIndex: 10,
                          })
                          .fromTo(
                            subMenu,
                            {
                              autoAlpha: 0,
                            },
                            {
                              autoAlpha: 1,
                              duration: 0.6,
                              ease: Power4.easeInOut,
                            }
                          )
                      )
                    })
                    return (
                      <div
                        class={`group relative`}
                        ref={(e) => (topLevelNavContainer = e)}
                        onMouseEnter={() => {
                          dropDownTl()?.play()
                        }}
                        onMouseLeave={() => {
                          dropDownTl()?.reverse()
                        }}
                      >
                        <a
                          class="block whitespace-nowrap px-20 py-16 font-camptonSemiBold text-18 text-inherits"
                          href={menuLink.url}
                          target={menuLink.target}
                        >
                          <span
                            class="border-b-2px border-transparant pb-2 transition-colors group-hover:border-inherits"
                            innerHTML={menuLink.title}
                          />
                        </a>
                        <Show when={menuLink.submenu} keyed>
                          {(s) => {
                            const isHeaderColumnStyle = s
                              .map((e) => {
                                return e.submenu
                              })
                              .every((element) => element)
                            return (
                              <nav
                                // ref={(e) => (subMenu = e)}
                                ref={(e) => {
                                  subMenu = e
                                }}
                                class={[
                                  'group-[.is-left]:left-[2.1875rem] group-[.is-left]:rounded-bl-20px group-[.is-left]:rounded-tr-20px',
                                  'group-[.is-right]:right-[2.1875rem] group-[.is-right]:rounded-br-20px group-[.is-right]:rounded-tl-20px',
                                  'absolute -z-10 h-0 overflow-hidden bg-white shadow-md',
                                ].join(' ')}
                              >
                                <div
                                  class={`${
                                    isHeaderColumnStyle ? '' : 'flex-wrap'
                                  } flex px-40 py-24`}
                                >
                                  <For each={menuLink.submenu}>
                                    {(subMenuLink1) => {
                                      return (
                                        <>
                                          <Switch>
                                            <Match when={subMenuLink1.submenu}>
                                              <div class="wrap flex-col pr-mobileGutter">
                                                <a
                                                  class={subMenuLink1TextStyles}
                                                  href={subMenuLink1.url}
                                                  innerHTML={subMenuLink1.title}
                                                  target={subMenuLink1.target}
                                                />
                                                <ul>
                                                  <For each={subMenuLink1.submenu}>
                                                    {(subMenuLink2) => (
                                                      <li>
                                                        <a
                                                          class={subMenuLink2TextStyles}
                                                          href={subMenuLink2.url}
                                                          target={subMenuLink2.target}
                                                        >
                                                          <span innerHTML={subMenuLink2.title} />
                                                        </a>
                                                      </li>
                                                    )}
                                                  </For>
                                                </ul>
                                              </div>
                                            </Match>
                                            <Match when={!subMenuLink1.submenu}>
                                              <div class="w-full flex-initial">
                                                <a
                                                  class={subMenuLink2TextStyles}
                                                  href={subMenuLink1.url}
                                                  innerHTML={subMenuLink1.title}
                                                  target={subMenuLink1.target}
                                                />
                                              </div>
                                            </Match>
                                          </Switch>
                                        </>
                                      )
                                    }}
                                  </For>
                                </div>
                              </nav>
                            )
                          }}
                        </Show>
                      </div>
                    )
                  }}
                </For>
              </nav>
            </div>
          </div>
        </Suspense>
      </header>
    </>
  )
}

type UtilityNavTypes = {
  callback: (e: boolean) => void
  ref?: HTMLDivElement | ((el: HTMLDivElement) => void) | undefined
}
const UtilityNav: Component<UtilityNavTypes> = (props) => {
  const [utilityNav] = createResource(fetchMainUtilityNav)
  const [openSearch, setOpenSearch] = createSignal<boolean>()
  const { form } = createForm({
    onSubmit: (values) => {
      // console.log(values)
      const searchUrl = `${window.currentSubsiteUrl}/?s=${encodeURIComponent(`${values.s}`)}`
      window.location.href = searchUrl
    },
  })
  let searchForm: HTMLDivElement
  let searchInput: HTMLInputElement

  createEffect(
    on(utilityNav, (u) => {
      props.callback(true)
    })
  )

  createEffect(
    on(
      openSearch,
      (s) => {
        if (s) {
          gsap.to(searchForm, {
            autoAlpha: 1,
            zIndex: 10,
            onComplete: () => {
              searchInput.focus()
            },
          })
        } else {
          // searchTl.reverse()
          gsap.to(searchForm, { autoAlpha: 0, zIndex: -10 })
        }
      },
      { defer: false }
    )
  )
  return (
    <div ref={props.ref} class="relative bg-darkerBlue px-mobileGutter 2xl:px-desktopOutsideGutter">
      <div class="relative mx-auto flex max-w-mainNavContainer justify-end gap-x-32 py-12">
        <For each={utilityNav()}>
          {(link) => (
            <a
              class="relative top-[0.125rem] text-15 font-normal leading-22 text-white link-hover hover:text-lightBlue"
              href={link.url}
              innerHTML={link.title}
            />
          )}
        </For>
        <button
          class="w-20 link-hover"
          onClick={() => {
            setOpenSearch(!openSearch())
          }}
        >
          <div class="sr-only bg-blue text-white">
            {openSearch() ? 'close search' : 'open search'}
          </div>
          <Show when={!openSearch()} fallback={<IconClose class="relative size-20 text-white" />}>
            <IconSearch class="relative size-16 text-white" />
          </Show>
        </button>
        <div ref={(e) => (searchForm = e)} class="absolute bottom-0 right-32 top-0 -z-10 opacity-0">
          <form ref={form} class="flex h-full items-center">
            <label>
              <span class="sr-only">search bar</span>
              <input
                type="text"
                name="s"
                ref={(e) => (searchInput = e)}
                class="h-40 w-320 rounded-20px text-15 focus:border-blue"
              />
            </label>
            <button
              onClick={() => {
                // console.log('search!')
              }}
              class="absolute bottom-0 right-0 top-0 flex items-center px-20"
            >
              <span class="sr-only">submit search</span>
              <IconSearch class="size-20 text-blue" />
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

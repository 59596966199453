import type { Config } from 'tailwindcss'
import { prose } from './prose'
import { rem } from './tailwind-helpers'

export const tailwindTheme = {
  fontFamily: {
    archerBold: 'ArcherBold',
    archerSemiBold: 'ArcherSemibold',
    camptonMedium: 'CamptonMedium',
    camptonSemiBold: 'CamptonSemiBold',
    camptonBook: 'CamptonBook',
    camptonBold: 'CamptonBold',
  },
  fontSize: {
    inherit: 'inherit',
    0: '0',
    8: '0.5rem',
    9: '0.5625rem',
    10: '0.625rem',
    11: '0.6875rem',
    12: '0.75rem',
    13: '0.8125rem',
    14: '0.875rem',
    15: '0.9375rem',
    16: '1rem',
    17: '1.0625rem',
    18: '1.125rem',
    19: '1.1875rem',
    20: '1.25rem',
    21: '1.3125rem',
    22: '1.375rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    31: '1.9375rem',
    32: '2rem',
    36: '2.25rem',
    37: '2.3125rem',
    42: '2.625rem',
    46: '2.875rem',
    48: '3rem',
    52: '3.25rem',
    54: '3.375rem',
    72: '4.5rem',
    82: '5.125rem',
  },
  fontWeight: {
    normal: '400',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  lineHeight: {
    0: '0',
    none: '1',
    normal: 'normal',
    '90%': '90%',
    '95%': '95%',
    '100%': '100%',
    '110%': '110%',
    '120%': '120%',
    '122%': '122%',
    '130%': '130%',
    '140%': '140%',
    '150%': '150%',
    '160%': '160%',
    '190%': '190%',
    120: '7.50rem',
    74: '4.63rem',
    65: '4.06rem',
    56: '3.50rem',
    51: '3.19rem',
    44: '2.75rem',
    43: '2.69rem',
    42: '2.63rem',
    40: '2.50rem',
    37: '2.31rem',
    33: '2.0625rem',
    31: '1.94rem',
    30: '1.88rem',
    28: '1.75rem',
    24: '1.5rem',
    23: '1.44rem',
    22: '1.38rem',
    21: '1.31rem',
    15: '0.94rem',
    14: '0.88rem',
    13: '0.81rem',
    '1-4': '1.4',
  },
  letterSpacing: {
    DEFAULT: '-2.5px',
    regular: '-2.5px',
    tighter: '-0.02em',
    tight: '-.01em',
    snug: '.01em',
    wide: '.04em',
    wider: '.1em',
    widest: '.4em',
    '-1%': '-1%',
    '-2%': '-2%',
    '1%': '1%',
    '2%': '2%',
    '4%': '4%',

    none: '0',
  },

  colors: {
    transparant: 'transparent',
    blue: '#002D74',
    adaAltBlue: '#007996',
    orange: '#FF6B00',
    adaOrangeAlt: '#B84D00',
    lightBlue: '#00A6CE',
    green: '#00735C',
    white: '#FFFFFF',
    ivory: '#F4F3EB',
    black: '#1A1A1A',
    yellow: '#FFB83D',
    purple: '#532E63',
    inherits: 'inherit',
    adaAltPurple: '#432550',
    red: '#F04D36',
    adaAltRed: '#D54031',
    gray2: '#4F4F4F',
    gray5: '#E0E0E0',
    darkerBlue: '#00245D',
    other: {
      blueHover: '#041B46',
    },
    legacy: {
      paleBlue: '#EEF4FB',
      paleOrange: '#fff5e6',
      paleOrangeDivider: '#FFEACC',
    },
  },
  borderRadius: {
    DEFAULT: '4px',
    '2px': '2px',
    '4px': '4px',
    '10px': '10px',
    '20px': '20px',
    '32px': '32px',
    '40px': '40px',
    '60px': '60px',
    '100px': '100px',
    full: '9999999px',
    none: '0',
  },
  borderWidth: {
    none: '0',
    DEFAULT: '1px',
    '1px': '1px',
    '2px': '2px',
    '3px': '3px',
    '4px': '4px',
    '6px': '6px',
  },
  spacing: {
    bodyGutterMobile: rem(20),
    bodyGutterDesktop: rem(60),
    '1px': '1px',
    '4px': '4px',
    mobileMenuHeight: rem(100),
    desktopMenuHeight: rem(155),
    desktopPageHeaderHeight: rem(200),
    mobilePageHeaderHeight: rem(280),
    mobileGutter: rem(36),
    0: rem(0),
    1: rem(1),
    2: rem(2),
    3: rem(3),
    4: rem(4),
    5: rem(5),
    6: rem(6),
    7: rem(7),
    8: rem(8),
    9: rem(9),
    10: rem(10),
    12: rem(12),
    13: rem(13),
    14: rem(14),
    15: rem(15),
    16: rem(16),
    18: rem(18),
    20: rem(20),
    21: rem(21),
    22: rem(22),
    24: rem(24),
    25: rem(25),
    26: rem(26),
    28: rem(28),
    30: rem(30),
    32: rem(32),
    34: rem(34),
    35: rem(35),
    36: rem(36),
    38: rem(38),
    40: rem(40),
    42: rem(42),
    44: rem(44),
    45: rem(45),
    46: rem(46),
    48: rem(48),
    50: rem(50),
    52: rem(52),
    54: rem(54),
    56: rem(56),
    58: rem(58),
    60: rem(60),
    62: rem(62),
    64: rem(64),
    66: rem(66),
    68: rem(68),
    70: rem(70),
    72: rem(72),
    74: rem(74),
    75: rem(75),
    76: rem(76),
    78: rem(78),
    80: rem(80),
    90: rem(90),
    96: rem(96),
    100: rem(100),
    106: rem(106),
    110: rem(110),
    120: rem(120),
    130: rem(130),
    144: rem(144),
    150: rem(150),
    160: rem(160),
    175: rem(175),
    192: rem(192),
    200: rem(200),
    240: rem(240),
    320: rem(320),
    560: rem(560),
    '9999px': '9999px',
    desktopOutsideGutter: rem(140),
    siteContainer: '85.25rem',
    // desktopColumnGutter: '1rem',
    desktopColumnWidth: '7.625rem',
    leafSidebar: rem(276),
  },
  screens: {
    '2xs': '320px',
    xs: '375px',
    sm: '641px',
    md: '992px',
    tribeDesktop: '828px',
    lg: '1024px',
    xl: '1440px',
    '2xl': '1536px',
    '1600': '1600px',
    '1800': '1800px',
    '3xl': '1920px',
  },
  extend: {
    opacity: {
      defaultHover: '0.8',
    },
    aspectRatio: {
      '380/240': '380 / 240',
    },
    keyframes: {
      wiggle: {
        '0%': { transform: 'rotate(0deg)' },
        '25%': { transform: 'rotate(-3deg)' },
        '75%': { transform: 'rotate(3deg)' },
        '100%': { transform: 'rotate(0deg)' },
      },
    },
    animation: {
      wiggle: 'wiggle 700ms cubic-bezier(0, 0, 0.2, 1) 1',
    },
    zIndex: {
      modal: '9000',
      modalBgFull: '8000',
      header: '7000',
      sidebarNav: '7000',
      betweenHeaderAndBg: '6000',
      modalBgBehindHeader: '5000',
      devHelper: '99999999999999',
      99999: '99999',
    },
    maxWidth: {
      layout: '118.75rem',
      270: rem(270),
      360: rem(360),
      630: rem(630),
      720: rem(720),
      960: rem(960),
      920: rem(920),
      1160: rem(1160),
      1200: rem(1200),
      siteContainer: rem(1364),
      1320: rem(1320),
      1440: rem(1440),
      1760: rem(1760),
      mainNavContainer: rem(1640),
      leafSidebar: rem(276),
    },

    backgroundImage: {
      btnHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), #002D74',
      tileGradient:
        'linear-gradient(180deg, rgba(26, 26, 26, 0.00) 0%, rgba(26, 26, 26, 0.70) 100%)',
      tileGradientHover:
        'linear-gradient(180deg, rgba(26, 26, 26, 0.00) 0%, rgba(26, 26, 26, 0.90) 100%)',
      chevronRight: `url('data:image/svg+xml,<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.8048 0.569824L0.5 1.97982L4.73829 6.56982L0.5 11.1598L1.8048 12.5698L7.35714 6.56982L1.8048 0.569824Z" fill="%23002D74"/></svg>')`,
      chevronLeft: `url('data:image/svg+xml,<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.1952 0.569824L7.5 1.97982L3.26171 6.56982L7.5 11.1598L6.1952 12.5698L0.642857 6.56982L6.1952 0.569824Z" fill="%23002D74"/></svg>')`,
      footerBg: `url('data:image/svg+xml,<svg width="371" height="592" viewBox="0 0 371 592" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.569824V591.064H370.525V474.331H120.089V0.569824H0Z" fill="%2300A6CE"/></svg>')`,
    },
    typography: prose,
    content: {
      footerBg: `url('data:image/svg+xml,<svg width="371" height="592" viewBox="0 0 371 592" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0.569824V591.064H370.525V474.331H120.089V0.569824H0Z" fill="%2300A6CE"/></svg>')`,
    },
  },
} satisfies Config['theme']

/* Card Hover - Dark BG */

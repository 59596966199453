// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import { cx } from '../../utils'
// import 'swiper/css/pagination'

export const loadSwipers = () => {
  const featuredHighlightSliders = Array.from(
    document.querySelectorAll('[data-swiper-style="featured-highlights-swiper"]')
  )
  featuredHighlightSliders.forEach((slider) => {
    const desktopPostsPerView = slider.getAttribute('data-swiper-desktop-posts-per-view') || '3'
    // convert to number
    const desktopPostsPerViewNumber = parseInt(desktopPostsPerView)
    const paginationEl = slider.querySelector('[data-swiper-pagination]')
    const nextButtonEl = slider.querySelector('[data-swiper-nav="next"]') as HTMLElement
    const prevButtonEl = slider.querySelector('[data-swiper-nav="prev"]') as HTMLElement

    new Swiper(slider as HTMLElement, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: nextButtonEl,
        prevEl: prevButtonEl,
        disabledClass: cx('opacity-50 cursor-not-allowed'),
      },

      pagination: {
        el: paginationEl as HTMLElement,
        clickable: true,
        bulletClass: cx(
          'size-16 bg-transparant rounded-full border-blue border-solid border-2px cursor-pointer hover:bg-blue'
        ),
        bulletActiveClass: cx('!bg-blue'),
      },
      breakpoints: {
        992: {
          slidesPerView: desktopPostsPerViewNumber,
          slidesPerGroup: desktopPostsPerViewNumber,
          spaceBetween: 20,
        },
      },
    })
  })

  const featuredImageSliders = Array.from(
    document.querySelectorAll('[data-swiper-style="featured-image-swiper"]')
  )
  featuredImageSliders.forEach((slider) => {
    const paginationEl = slider.querySelector('[data-swiper-pagination]')
    const nextButtonEl = slider.querySelector('[data-swiper-nav="next"]') as HTMLElement
    const prevButtonEl = slider.querySelector('[data-swiper-nav="prev"]') as HTMLElement
    const desktopSlides = parseInt(slider.getAttribute('data-swiper-desktop-slides') || '2', 10)
    new Swiper(slider as HTMLElement, {
      modules: [Navigation, Pagination],
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
      navigation: {
        nextEl: nextButtonEl,
        prevEl: prevButtonEl,
        disabledClass: cx('opacity-50 cursor-not-allowed'),
      },
      pagination: {
        el: paginationEl as HTMLElement,
        clickable: true,
        bulletClass: cx(
          'size-16 bg-transparant rounded-full border-blue border-solid border-2px cursor-pointer hover:bg-blue'
        ),
        bulletActiveClass: cx('!bg-blue'),
      },
      breakpoints: {
        992: {
          enabled: true,
          slidesPerView: desktopSlides,
          slidesPerGroup: desktopSlides,
          spaceBetween: 60,
        },
      },
    })
  })
}

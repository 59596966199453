import { attachDevtoolsOverlay } from '@solid-devtools/overlay'
import { MetaProvider } from '@solidjs/meta'
import { Route, Router } from '@solidjs/router'

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { QueryClient, QueryClientProvider } from '@tanstack/solid-query'
import { SolidQueryDevtools } from '@tanstack/solid-query-devtools'
import { persistQueryClient } from '@tanstack/solid-query-persist-client'
import { getCurrentElement } from 'solid-element'
import {
  createEffect,
  createSignal,
  ErrorBoundary,
  lazy,
  Match,
  onMount,
  Show,
  Switch,
  type Component,
  type ParentComponent,
} from 'solid-js'
import { AppContextProvider, useAppContext } from './context/context'
import type { AppSettings } from './types'
import { logger } from './utils'
// import { CaseStudyHome } from './pages/CaseStudyHome'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: process.env.NODE_ENV === 'development' ? 0 : 5 * 60 * 1000, // 5 minutes,
      gcTime: 1000 * 60 * 60, // 1 hour
    },
  },
})

const PageCaseStudyHome = lazy(() => import('./pages/PageCaseStudyHome'))
const PageSingleCaseStudy = lazy(() => import('./pages/PageSingleCaseStudy'))
const PageCaseStudyFavorites = lazy(() => import('./pages/PageCaseStudyFavorites'))

const PageSavedSearches = lazy(() => import('./pages/PageSavedSearches'))

const PageLayout: ParentComponent = (props) => {
  return <div class="mb-80 space-y-40 md:space-y-80">{props.children}</div>
}

// Create a persister using localforage
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
})

// Persist the QueryClient instance
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  buster: __BUILD_HASH__,
})

const AppRouter: Component<{
  settings: AppSettings
}> = (props) => {
  const { setActiveQuery, activeQuery, mmlLocalStorage } = useAppContext()

  onMount(() => {
    setActiveQuery('appSettings', props.settings)
  })

  return (
    <div>
      <Router base={props.settings.app_slug} root={PageLayout}>
        <Route
          path={'/'}
          component={PageCaseStudyHome}
          info={{ breadcrumb: { label: 'Home', path: '/' } }}
        />
        <Route
          path={'/favorites'}
          component={PageCaseStudyFavorites}
          info={{ breadcrumb: { label: 'Favorites', path: '/favorites' } }}
        />
        <Route
          path={'/saved-searches'}
          component={PageSavedSearches}
          info={{
            breadcrumb: { label: 'Saved Searches', path: '/saved-searches' },
          }}
        />
        <Route
          path={'/:slug'}
          component={PageSingleCaseStudy}
          info={{
            breadcrumb: { label: 'Case Study', path: '/:slug' },
          }}
        />
        <Route
          path={'/testing'}
          component={() => <>test</>}
          info={{ breadcrumb: { label: 'Testing', path: '/testing' } }}
        />
        <Route
          path={'*404'}
          component={() => <>404</>}
          info={{ breadcrumb: { label: '404', path: '*404' } }}
        />
      </Router>

      <Show when={process.env.NODE_ENV !== 'production'}>
        <div class="fixed bottom-0 left-0 hidden max-w-720 space-y-10 divide-y overflow-scroll border border-black bg-white p-20 placeholder-opacity-10">
          <div>
            <h4 class="text-center">search state</h4>
            <pre>{JSON.stringify(activeQuery, null, 2)}</pre>
          </div>
          <div>
            <h4 class="text-center">local storage</h4>
            <pre>{JSON.stringify(mmlLocalStorage, null, 2)}</pre>
          </div>
          <div>app path: {props.settings.app_slug}</div>
        </div>
      </Show>
    </div>
  )
}

const AppConfiguration: Component = () => {
  const { caseStudiesAppNavPathQuery } = useAppContext()

  return (
    <Switch>
      <Match when={caseStudiesAppNavPathQuery.isPending}>
        <div class="type-h3-bold py-100 text-center">Loading...</div>
      </Match>
      <Match when={caseStudiesAppNavPathQuery.isError}>
        <div class="text-center">Error: {caseStudiesAppNavPathQuery.error?.message}</div>
      </Match>
      <Match when={caseStudiesAppNavPathQuery.isSuccess && caseStudiesAppNavPathQuery.data} keyed>
        {(settings) => <AppRouter settings={settings} />}
      </Match>
    </Switch>
  )
}

export const CaseStudiesApp: Component = () => {
  attachDevtoolsOverlay({
    defaultOpen: false,
    noPadding: true,
  })

  const [shadowRoot, setShadowRoot] = createSignal<ShadowRoot>()
  createEffect(() => {
    const shadow = getCurrentElement()?.shadowRoot
    if (shadow) {
      setShadowRoot(shadow)
    }
  })

  return (
    <div class="mml-onemagnify-plugin">
      <QueryClientProvider client={queryClient}>
        <SolidQueryDevtools
          shadowDOMTarget={shadowRoot()}
          initialIsOpen={false}
          buttonPosition="top-right"
        />
        <AppContextProvider>
          <ErrorBoundary
            fallback={(e) => {
              logger(e, 'error')
              return <>{JSON.stringify(e)}</>
            }}
          >
            <MetaProvider>
              <AppConfiguration />
            </MetaProvider>
          </ErrorBoundary>
        </AppContextProvider>
      </QueryClientProvider>
    </div>
  )
}

// export default App

import type { Properties } from 'csstype'
import { rem } from './tailwind-helpers'
interface ExtendedProperties extends Properties {
  '&:hover'?: Properties
  '&:focus'?: Properties
  '&:active'?: Properties
  '&:disabled'?: Properties
  '&:visited'?: Properties
  '@media (min-width: 992px)'?: Properties
}

const removeYMargin: ExtendedProperties = {
  marginTop: '0',
  marginBottom: '0',
}

type CssClassStyles = Record<string, ExtendedProperties>
const DESKTOP_STYLES: CssClassStyles = {
  '.type-h1-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(100),
    lineHeight: '90%',
    letterSpacing: '0.015em',
    ...removeYMargin,
  },
  '.type-h1-bold-smaller': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(72),
    lineHeight: '90%',
    letterSpacing: '0.0108em',
    ...removeYMargin,
  },
  '.type-h2-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(51),
    lineHeight: '90%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-h3-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(41),
    lineHeight: '90%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-h3-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(41),
    lineHeight: '100%',
    letterSpacing: '-0.01em',
    ...removeYMargin,
  },
  '.type-h4-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(32),
    lineHeight: '100%',
    letterSpacing: '-0.01em',
    ...removeYMargin,
  },
  '.type-h5-bold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(32),
    lineHeight: '160%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-h6-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-h7-semibold-all-caps': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '160%',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    ...removeYMargin,
  },
  '.type-p1-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '160%',
    letterSpacing: '0.02em',
    ...removeYMargin,
  },
  '.type-p1-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '160%',
    letterSpacing: '0.02em',
    ...removeYMargin,
  },
  '.type-p2-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(21),
    lineHeight: '160%',
    letterSpacing: '0.02em',
    ...removeYMargin,
  },
  '.type-p2-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(21),
    lineHeight: '160%',
    letterSpacing: '0.02em',
    ...removeYMargin,
  },
  '.type-p3-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(18),
    lineHeight: '160%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-p3-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(18),
    lineHeight: '160%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-p4-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '150%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-p4-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '150%',
    letterSpacing: '0',
    ...removeYMargin,
  },
}

const MOBILE_STYLES: CssClassStyles = {
  '.type-mobile-h1-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(51),
    lineHeight: '90%',
    letterSpacing: '0.015em',
    ...removeYMargin,
  },
  '.type-mobile-h2-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(32),
    lineHeight: '110%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-mobile-h2-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(32),
    lineHeight: '100%',
    letterSpacing: '-0.01em',
    ...removeYMargin,
  },
  '.type-mobile-h3-bold': {
    fontFamily: "'ArcherBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '100%',
    letterSpacing: '-0.01em',
    ...removeYMargin,
  },
  '.type-mobile-h3-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '100%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-mobile-h4-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(25),
    lineHeight: '110%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-mobile-h5-semibold': {
    fontFamily: "'ArcherSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(18),
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    ...removeYMargin,
  },
  '.type-mobile-h6-semibold-all-caps': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '120%',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    ...removeYMargin,
  },
  '.type-mobile-h7-medium-all-caps': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(14),
    lineHeight: '110%',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    ...removeYMargin,
  },
  '.type-mobile-h7-semibold-all-caps': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(14),
    lineHeight: '110%',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    ...removeYMargin,
  },
  '.type-mobile-p1-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(18),
    lineHeight: '160%',
    letterSpacing: '0',
  },
  '.type-mobile-p1-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(18),
    lineHeight: '160%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-mobile-p2-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '150%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-mobile-p2-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(16),
    lineHeight: '150%',
    letterSpacing: '0',
  },
  '.type-mobile-p3-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(14),
    lineHeight: '120%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  '.type-mobile-p3-semibold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(14),
    lineHeight: '120%',
    letterSpacing: '0',
    ...removeYMargin,
  },
  // '.type-mobile-p4-semibold': {
  //   fontFamily: "'CamptonSemiBold', sans-serif",
  //   fontWeight: 'normal',
  //   fontSize: rem(16),
  //   lineHeight: '150%',
  //   letterSpacing: '0',
  // },
}

const OTHER_STYLES: CssClassStyles = {
  '.type-cta-medium': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(21),
    lineHeight: '85%',
    letterSpacing: '0.01em',
    ...removeYMargin,
  },
  '.type-cta-bold': {
    fontFamily: "'CamptonSemiBold', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(21),
    lineHeight: '85%',
    letterSpacing: '0.01em',
    ...removeYMargin,
  },
  '.breadcrumb-default': {
    fontFamily: "'CamptonMedium', sans-serif",
    fontWeight: 'normal',
    fontSize: rem(14),
    lineHeight: '160%',
    letterSpacing: '0.0122em',
    textTransform: 'uppercase',
    ...removeYMargin,
  },

  '.type-page-header-divider': {
    ...MOBILE_STYLES['.type-mobile-h2-bold'],
    // color: tailwindTheme.colors.blue,
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
    borderBottom: '6px',
    borderColor: '#00A6CE',
    borderStyle: 'solid',
    paddingBottom: rem(10),
    marginTop: rem(40),
    marginBottom: rem(40),

    '@media (min-width: 992px)': {
      ...DESKTOP_STYLES['.type-h2-bold'],
      lineHeight: '120%',
      marginTop: rem(80),
      marginBottom: rem(80),
    },
  },
}

export const FIGMA_TYPOGRAPHY = {
  ...DESKTOP_STYLES,
  ...MOBILE_STYLES,
  ...OTHER_STYLES,
}

import { getCurrentElement } from 'solid-element'

import { createEffect, createMemo, createSignal, on, type ParentComponent } from 'solid-js'

type KyMobileDropDownToggleProps = {
  class?: string
}
export const KyMobileDropDownToggle: ParentComponent<KyMobileDropDownToggleProps> = (props) => {
  const currentElement = getCurrentElement()
  const [isOpen, setIsOpen] = createSignal(false)

  const container = createMemo(() => {
    return currentElement?.children[0]
  })

  const toggleButton = createMemo(() => {
    return currentElement?.querySelector('[ky-mobile-drop-down-toggle="toggle"]')
  })
  toggleButton()?.addEventListener('click', () => {
    setIsOpen(!isOpen())
  })
  createEffect(
    on(
      isOpen,
      (isOpen) => {
        if (isOpen) {
          container()?.classList.add('is-open')
          container()?.classList.add('z-50')
        } else {
          container()?.classList.remove('is-open')
          container()?.classList.remove('z-50')
        }
      },
      { defer: true }
    )
  )
  return <>{props.children}</>
}
